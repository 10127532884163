<template>
  <a-layout>
    <a-layout-header class="header">
      <div class="logo">{{ tenantName }} </div>

      <a-menu
        class="top-right-menu"
        mode="horizontal"
        theme="dark"
        @click="handleMenuClick"
        :items="items"
      />
      <!-- <a-dropdown
        class="top-right-menu"
        theme="dark"
        mode="horizontal"
        :style="{ lineHeight: '64px' }"
      >
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="logout">退出登录</a-menu-item>
          </a-menu>
        </template>
        <a-button>
          <MyIcon type="icon-account" />
          {{ accountName }}
          <MyIcon type="icon-arrow-down" />
        </a-button>
      </a-dropdown> -->
    </a-layout-header>
    <a-layout>
      <LeftNav />

      <a-layout style="padding: 5px">

        <a-layout-content class="content">
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import ApiServices from '@/services/ApiServices';
import LeftNav from '../LeftNav';
import { defineComponent, createVNode, computed, reactive, ref, h } from 'vue';
import router from '@/router';

import { UserOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    LeftNav,
  },
  setup() {

    const accountName = ref('');
    const tenantName = ref('');

    const account = JSON.parse(localStorage.getItem('account'));
    accountName.value = account?.tenant_account_name;
    tenantName.value = account?.name;

    const handleMenuClick = (e) => {
      if(e.key == 'logout') {
        localStorage.clear();
        router.push("/login");
      }
    }

    const items = ref([{
      key: 'account',
      icon: () => h(UserOutlined),
      label: accountName,
      title: accountName,
      children: [{
        label: '退出登录',
        key: 'logout',
      }]
    }]);

    return {
      accountName,
      tenantName,
      handleMenuClick,
      items
    }
  }
});
</script>

<style>
.header {
  background-color: white;
  padding:0;
  margin:0;
}

.logo {
  float: left;
  width: 120px;
  font-size: 24px;
  text-align: center;
  color: white;
}

.top-right-menu {
  float: right;
}

.content {
  background: #fff;
  padding: 20px;
  margin: 0;
  min-height: 680px;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from './router';
import { createPinia } from 'pinia';
import Antd from 'ant-design-vue/lib';
import { createFromIconfontCN } from '@ant-design/icons-vue';
// import 'ant-design-vue/dist/antd.less';
import './style.less';

import i18n from './i18n';

const app = createApp(App);

app.provide('$router', router)

const pinia = createPinia();
app.use(pinia);

const MyIcon = createFromIconfontCN({
    // scriptUrl: 'http://fastbook.zhuliansoft.com/assets/iconfont.js'
    scriptUrl: '//at.alicdn.com/t/c/font_3291143_ty8g53ttn4o.js'
});
app.component('MyIcon', MyIcon);

app.use(i18n);

app.use(Antd);

app.use(router).mount('#app');
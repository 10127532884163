<template>
    <div>
      <div class="mb-2" style="overflow:hidden;">
        <a-form
          :model="formData"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          @finish="finishSyncOrder"
        >
          <a-form-item
            label="归属店铺"
          >
              <a-select
              show-search
              allowClear
              :options="tenantStores"
              :field-names="{ label: 'store_name', value: 'id'}"
              v-model:value="formData.store_id"
            >
            </a-select>
          </a-form-item>
          <a-form-item
              name="id"
              label="订单编号"
            >
            <a-input
              v-model:value="formData.tid"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 6, span: 14 }">
            <a-button type="primary" html-type="submit">
              {{ $t('actions.submit') }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </template>
  
  
  <script>
  import { defineComponent, onMounted, ref} from 'vue';
  import { useRoute } from 'vue-router';
  import { message } from 'ant-design-vue';
  import { useI18n } from "vue-i18n";
  
  import { storeToRefs } from 'pinia';
  import { useTenantStore, useBookingsStore } from '@/stores';
  
  export default defineComponent({
    props: [],
    components: {},
    setup(props, ctx) {
  
      const { t } = useI18n();
      const routeName = useRoute().name;
      document.title = t(routeName);
      const formRef = ref(null);
  
      const tenantStore = useTenantStore();
      const {
        formData,
        tenantStores,
        pagination,
      } = storeToRefs(tenantStore);

      const bookingsStore = useBookingsStore();
  
      // 初始化
      onMounted(() => {
        tenantStore.fetchStores();
        tenantStore.formData.tid = '';
      });
  
      const finishSyncOrder = () => {
        bookingsStore.syncTmallOrder(formData.value);
      }

      return {
        routeName,
        formData,
        tenantStores,
        pagination,
        finishSyncOrder
      }
    },
  });
  </script>
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';
import { message } from 'ant-design-vue';


export const useMailsStore = defineStore({
  id: 'mails',
  state: () => ({
    mails: [],
    detail: {},
    editingData: {},
    loading: false,
    modalVisible: false,
    modalTitle: '',
    editModalVisible: false,
    detailModalVisible: false,
    formState: {
      id: '',
      name: '',
      id_card: '',
      phone: '',
      email: '',
      passport: '',
      gender: '',
      birthday: '',
      address: '',
    },
    searchState: {
      pageNum: 1,
      pageSize: 5,
      order_no: '',
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    spinningState: {
      listSpinning: false,
      editSpinning: false,
      addSpinning: false,
      deleteSpinning: false,
    },
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchAll() {
      this.spinningState.listSpinning = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/mail`, this.searchState)
        .then(response => response)
        .then(data => {
          if (data.code === 520) {
            this.mails = data.message.data || [];
            this.searchState.total = data.message.total || 0;
            this.pagination = this.computedPagination;
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.spinningState.listSpinning = false;
        });
    },

    async update() {
      this.spinningState.editSpinning = true;

      this.editingData.order_no = this.searchState.order_no;

      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/updateMail`, this.editingData)
        .then(response => response)
        .then(data => {
          if (data.code === 520) {
            message.success('发送成功');
            this.fetchAll();
            this.editModalVisible = false;
          } else {
            message.error(data.message);
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          
          
          this.spinningState.editSpinning = false;
        });
    },

    // async delete(params) {
    //   this.spinningState.deleteSpinning = true;
    //   await fetchWrapper.post(process.env.VUE_APP_API_URL + `/memo/delete`, params)
    //     .then(response => response.data)
    //     .then(data => {
    //       if (data.code === 520) {
    //         console.log('Deleted!');
    //       }
    //     })
    //     .catch(error => console.log(error))
    //     .finally(() => {
    //       this.fetchAll();
    //       this.spinningState.deleteSpinning = false;
    //     });
    // },

  }
});

<template>
<div>
  <a-row class="page-header mb-3">
    <a-col :span="24">
      <h2>{{routeName ? $t(routeName) : "Untitled"}}</h2>
    </a-col>
  </a-row>

  <a-row :gutter="24">

    <a-col :span="24">

      <a-row class="page-search mb-4">
        <a-col :span="24">
          <a-form
            layout="inline"
            :model="searchState"
            @finish="search"
          >
            <a-form-item>
              <a-input v-model:value="searchState.supplier_name" :placeholder="$t('fields.title')"></a-input>
            </a-form-item>
            <a-form-item>
              <a-input style="width: 268px" v-model:value="searchState.email" :placeholder="$t('fields.email')"></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                v-model:value="searchState.status"
                allowClear="true"
                placeholder = '状态'
                style="width:100px"
              >
                <a-select-option value="enabled">启用</a-select-option>
                <a-select-option value="disabled">停用</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-space>
                <a-button type="default" html-type="submit">
                  <MyIcon type="icon-search1" />
                  {{ $t('actions.search') }}
                </a-button>
                <a-button type="default" @click="()=>{this.$router.go(0);}">
                  <MyIcon type="icon-reload" />
                  {{ $t('actions.reset') }}
                </a-button>
                <a-button type="primary" @click="createRecord()" class="ml-3">
                  <MyIcon type="icon-iconfontjiahao1" />
                  {{ $t('actions.add') }}
                </a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-table
        :columns="columns"
        :data-source="suppliers"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ text, record, column }">
          <template v-if="column.dataIndex == 'supplier_name'">
            <a @click="editRecord(record)">{{ text }}</a>
          </template>
          <template v-if="column.dataIndex == 'status'">
            <a-switch :checked="text == 'enabled'" @change="changeStatus(record)"/>
          </template>
          <template v-if="column.dataIndex == 'actions'">
            <a-space>
              <a-button @click="editRecord(record)" ><MyIcon type="icon-edit"/>{{ $t('actions.edit') }}</a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>

  <a-modal
    v-model:visible="modalVisible"
    v-model:title="modalTitle"
    :maskClosable=false
    style="width: 680px"
    :footer="null"
    distroyOnclose="true"
  >
    <a-form
      :model="formState"
      name="editForm"
      :ref="formRef"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="finishRecordForm"
    >
      <a-form-item
          name="id"
          :style="{display: 'None'}"
          v-if="'id' in formState"
        >
        <a-input type="hidden" v-model:value="formState.id" />
      </a-form-item>
      <a-form-item
        label="名称"
        name="supplier_name"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-input v-model:value="formState.supplier_name" />
      </a-form-item>

      <a-form-item
        label="邮箱"
        name="email"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-input v-model:value="formState.email" />
      </a-form-item>

      <a-form-item
        label="备注"
        name="comment"
        :rules="[{ required: false, message: '' }]"
      >
        <a-textarea
          v-model:value="formState.comment"
          rows="5"
        />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 6, span: 16 }">
        <a-button type="primary" html-type="submit">
          {{ $t('actions.submit') }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>

</div></template>

<script>

import { defineComponent, computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";

import { storeToRefs } from 'pinia';
import { useSuppliersStore } from "@/stores";

export default defineComponent({
  components: {},
  setup() {

    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);
    const formRef = ref(null);

    const suppliersStore = useSuppliersStore();
    const { suppliers, searchState, formState, pagination, loading, modalVisible, modalTitle } = storeToRefs(suppliersStore);

    const columns = [
      {
        title: t('fields.id'),
        dataIndex: "id",
        width: '5%'
      },
      {
        title: t('fields.title'),
        dataIndex: "supplier_name",
      },
      {
        title: t('fields.email'),
        dataIndex: "email",
        width: '20%'
      },
      {
        title: '备注',
        dataIndex: 'comment'
      },
      {
        title: '启用',
        dataIndex: "status"
      },
      {
        title: t('fields.actions'),
        dataIndex: "actions",
        width: '10%'
      }
    ];

    // 初始化
    suppliersStore.searchState.pageSize = 10;
    suppliersStore.searchState.pageNum = 1;
    suppliersStore.fetchAll();

    // 搜索
    const search = () => {
      suppliersStore.fetchAll();
    }

    // 表格分页
    const handleTableChange = (page, filters, sorter) => {
      suppliersStore.searchState.pageNum = page.current;
      suppliersStore.searchState.pageSize = page.pageSize;
      suppliersStore.fetchAll();
    };

    // 创建记录表单
    const createRecord = () => {
      suppliersStore.modalVisible = true;
      suppliersStore.modalTitle = t('actions.add') + t('menu.suppliers');
      for(let key in suppliersStore.formState) {
        suppliersStore.formState[key] = '';
      }
    }

    // 编辑记录表单
    const editRecord = (record) => {
      suppliersStore.modalVisible = true;
      suppliersStore.modalTitle = t('actions.edit') + t('menu.suppliers');
      for(let key in suppliersStore.formState) {
        suppliersStore.formState[key] = record[key];
      }
    }

    // 编辑表单提交
    async function finishRecordForm(values) {
      await suppliersStore.update(values);
    };

    async function changeStatus (record){
      const index = suppliersStore.suppliers.findIndex((item) => item.id === record.id);
      if (index >= 0) {
        await suppliersStore.changeStatus(record);
      }
    }

    return {
      routeName,
      search,
      searchState,
      columns,
      suppliers,
      pagination,
      handleTableChange,
      formRef,
      loading,
      modalVisible,
      modalTitle,
      createRecord,
      editRecord,
      formState,
      finishRecordForm,
      changeStatus
    }
  },
});
</script>
<template>
<div>
  <a-row class="page-header">
    <a-col :span="24">
      <div class="page-title">
        <h2>{{routeName ? $t(routeName) : "Untitled"}}</h2>
      </div>
    </a-col>
  </a-row>

  <a-card title="数据汇总" :style="{marginTop: '20px'}">
    <a-card-grid style="text-align: center">
      <a-statistic title="总销售" :precision="2"  :value="statistics.total_sales" />
    </a-card-grid>
    <a-card-grid style="text-align: center">
      <a-statistic title="总毛利" :precision="2"  :value="statistics.total_profit" />
    </a-card-grid>
    <a-card-grid style="text-align: center">
      <a-statistic title="有效订单" :value="statistics.total_bookings" />
    </a-card-grid>
  </a-card>

  <a-row :gutter="[24, 24]" :style="{marginTop: '20px'}">
    <a-col :span="12">
      <a-card title="最新预订">
        <a-spin :spinning="spinningState.newBookingSpinning">
          <a-list>
            <a-list-item v-for="booking in bookings" :key="booking.id" >

              <a-list-item-meta
              >
                <template #title>
                  {{ booking.project_name }}
                </template>
              </a-list-item-meta>
              <div>
                <a-statistic valueStyle="font-size:14px;" :value="booking.create_time" />
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
    </a-card>
    </a-col>

    <a-col :span="12">
      <a-card title="最新产品">
        <a-spin :spinning="spinningState.newProductSpinning">
          <a-list>
            <a-list-item v-for="product in products" :key="product.id">
              <a-list-item-meta
              >
                <template #title>
                  {{ product.name }}
                </template>
              </a-list-item-meta>
              <div>
                <a-statistic valueStyle="font-size:14px;" :value="product.create_time" />
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
    </a-card>
    </a-col>
    <a-col>

    </a-col>
  </a-row>

  <a-modal
    v-model:visible="modalVisible" 
    v-model:title="modalTitle"
    :maskClosable=false
    :footer="null"
    @ok="handleOk"
  >
    <a-form
      :model="formState"
      name="editForm"
      ref="formRef"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <a-form-item
          name="id"
          :style="{display: 'None'}"
          v-if="formEditStatus == true"
        >
        <a-input type="hidden" v-model:value="formState.id" />
      </a-form-item>
      <a-form-item
        label="手机号"
        name="phone"
        :rules="[{ required: true, message: '请填写手机号' }]"
      >
        <a-input v-if="formEditStatus == false" v-model:value="formState.phone" />
        <span v-else>{{ formState.phone }}</span>
      </a-form-item>
      <a-form-item
        label="别称"
        name="nickname"
        :rules="[{ required: false, message: '' }]"
      >
        <a-input v-model:value="formState.nickname" />
      </a-form-item>

      <a-form-item
        label="备注"
        name="remark"
        :rules="[{ required: false, message: '' }]"
      >
        <a-textarea v-model:value="formState.remark" />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
        <a-button type="primary" html-type="submit">确认提交</a-button>
      </a-form-item>
    </a-form>
  </a-modal>

</div>
</template>

<script>

import { defineComponent, computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";
import ApiServices from "@/services/ApiServices";

import { storeToRefs } from 'pinia';
import { useDashboardStore } from '@/stores';

const columns = [
  {
    title: "客户",
    dataIndex: "avatar",
    width: '8%'
  },
  {
    title: "手机号",
    dataIndex: "phone",
    width: '15%'
  },
  {
    title: "别称",
    dataIndex: "nickname",
    width: "15%"
  },
  {
    title: "备注",
    dataIndex: "remark",
  },
  {
    title: "创建时间",
    dataIndex: "create_time_format",
    width: '15%'
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: '15%'
  }
];


export default defineComponent({
  props: {
    msg: String,
  },
  components: {},
  setup() {

    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);
    const formRef = ref(null);

    const store = useDashboardStore();
    const {
      statistics,
      products,
      bookings,
      spinningState
    } = storeToRefs(store);

    store.fetchStatistics();
    store.fetchBookings();
    store.fetchProducts();

    const searchState = reactive({
      phone: '',
      nickname: '',
      total: 0,
      pageNum: 1,
      pageSize: 10
    })

    const dataSource = ref([]);
    const loading = ref(false);
    const syncLoading = ref(false);
    const modalVisible = ref(false);
    const modalTitle = ref('新增客户');
    const formEditStatus = ref(false);

    const search = () => {
      searchState.pageNum = 1;
      loadDataSource();
    }

    const formState = reactive({
      id: '',
      phone: '',
      nickname: '',
      remark: ''
    });

    const onFinish = values => {
      console.log('Success:', values);
      ApiServices.tenantUpdate(values)
        .then(response => {
          let data = response.data;
          if(data.err_code === 520) {
            message.success('更新成功');
            modalVisible.value = false;
            loadDataSource();
          }else{
            message.error(data.err_msg);
          }
        })
        .catch(e => {
          console.log(e);
        });
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const confirmDelete = (record_id) => {
      let post_data = {
        record_id: record_id
      }
      ApiServices.accountDelete(post_data)
        .then(response => {
          let data = response.data;
          if(data.code === 520) {
            message.success('更新成功')
            loadDataSource()
          }else{
            message.error('更新失败')
          }
        })
        .catch(e => {
          console.log(e);
        });
    }

    const loadDataSource = () => {
      loading.value = true;
      ApiServices.tenantList(searchState)
        .then(response => {
          let result = response.data.result;
          if(response.data.err_code === 520) {
            dataSource.value = result.data;
            searchState.total = result.total;
          }else{
            console.log('error')
          }
          loading.value = false;
        })
        .catch(e => {
          console.log(e);
        });
    }

    const pagination = computed(() => ({
      total: searchState.total,
      current: searchState.pageNum,
      pageSize: searchState.pageSize,
      defaultPageSize: 10,
      showTotal:  total =>`总共 ${total} 项`,
      showSizeChanger: true,
      showQuickJumper: true
    }))

    const handleTableChange = (page, filters, sorter) => {
      searchState.pageNum = page.current;
      searchState.pageSize = page.pageSize;
      loadDataSource();
    };

    const createRecord = () => {
      modalVisible.value = true;
      modalTitle.value = '新增客户';
      formEditStatus.value = false;
      formRef.value.resetFields();
      for(let key in formState) {
        formState[key] = '';
      }
    }

    const editRecord = (record) => {
      modalVisible.value = true;
      modalTitle.value = '编辑客户';
      formEditStatus.value = true;
      for(let key in formState) {
        formState[key] = record[key];
      }
    }

    const detail = (record) => {
      router.push({
        path: 'tenantDetail',
        query: {
          id: record.id
        }
      })
    }

    return {
      routeName,
      searchState,
      search,
      handleTableChange,
      loadDataSource,
      columns,
      dataSource,
      pagination,
      loading,
      syncLoading,
      modalVisible,
      modalTitle,
      createRecord,
      editRecord,
      detail,
      formState,
      onFinish,
      onFinishFailed,
      formEditStatus,
      formRef,
      confirmDelete,
      statistics,
      products,
      bookings,
      spinningState
    }
  },
});
</script>
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';

export const useDashboardStore = defineStore({
  id: 'dashboard',
  state: () => ({
    statistics: {},
    products: [],
    bookings: [],
    spinningState: {
      newBookingSpinning: false,
      newProductSpinning: false
    },
    selectedTraveler: {},
    editingMemo: {},
    loading: false,
    modalVisible: false,
    modalTitle: '',
    editModalVisible: false,
    formState: {
      id: '',
      name: '',
      id_card: '',
      phone: '',
      email: '',
      passport: '',
      gender: '',
      birthday: '',
      address: '',
    },
    searchState: {
      pageNum: 1,
      pageSize: 5,
      order_no: '',
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    spinningState: {
      listSpinning: false,
      editSpinning: false,
      addSpinning: false,
      deleteSpinning: false,
    },
  }),

  getters: {},

  actions: {
    async fetchStatistics() {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/dashboardStatistics`)
        .then(response => response)
        .then(data => {
          if (data.code === 520) {
            this.statistics = data.message
          }
        })
        .catch(error => console.log(error))
        .finally();
    },

    async fetchBookings() {
        this.spinningState.newBookingSpinning = true;
        await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/dashboardBooking`)
          .then(response => response)
          .then(data => {
            if (data.code === 520) {
              this.bookings = data.message.data || [];
              this.spinningState.newBookingSpinning = false;
            }
          })
          .catch(error => console.log(error))
          .finally();
      },

    async fetchProducts() {
        this.spinningState.newProductSpinning = true;
        await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/dashboardProduct`)
          .then(response => response)
          .then(data => {
            if (data.code === 520) {
              this.products = data.list.data || [];
              this.spinningState.newProductSpinning = false;
            }
          })
          .catch(error => console.log(error))
          .finally();
      },

  }
});

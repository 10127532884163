import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { message } from 'ant-design-vue';

export const useLoginStore = defineStore({
  id: 'login',
  state: () => ({
    returnUrl: null,
    token: localStorage.getItem('token')
  }),
  actions: {
    async login(username, password) {

      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/login/check`, { username, password })
        .then(response => response)
        .then(data => {
          if (data.code === 520) {
            let token = data.message.token;
            this.token = token;
            localStorage.setItem('token', token);
            let account = data.message.account;
            localStorage.setItem('account', JSON.stringify(account));
          } else {
            message.error(data.message);
          }
        })
        .catch(error => console.log(error))
        .finally(() => {});
    },
    logout() {
      this.token = null;
      localStorage.removeItem('token');

    }
  }
});

import axios from "axios";

const service = axios.create({
  headers: {
    "Content-type": "application/json",
    "token": sessionStorage.getItem("token")
  }
});

// 解决重新登录token不刷新的问题
service.interceptors.request.use(config => {
  config.headers.token = sessionStorage.getItem("token");
  return config
}, error => {return Promise.reject(error)
})

export default service;

<template>
  <div>
    <div class="mb-2" style="overflow:hidden;">
      <a-form
        :model="editingMemo"
        name="editForm"
        ref=""
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        autocomplete="off"
        @finish="updateMemo"
      >
        <a-form-item
          label="类别"
        >
          <a-select
            style="width: 100%"
            v-model:value="editingMemo.type"
          >
            <a-select-option value="0">预订</a-select-option>
            <a-select-option value="1">售后</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
            name="id"
            label="内容"
          >
          <a-textarea
            v-model:value="editingMemo.content"
            :rows="3"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 6, span: 14 }">
          <a-button type="primary" html-type="submit">
            {{ $t('actions.submit') }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>

    <a-spin :spinning="spinningState.listSpinning">
      <a-table
        :dataSource="memos"
        :columns="columns"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ text, record, column }">
          <template v-if="column.dataIndex == 'content'">
            <h3>
              <a-typography-text type="secondary">
                {{ text }}
              </a-typography-text>
            </h3>
            <p>{{ record.create_time }}</p>
          </template>
          <template v-if="column.dataIndex == 'type'">
            <a-tag color="success" v-if="text === 0">
              预订
            </a-tag>
            <a-tag color="warning" v-if="text === 1">
              售后
            </a-tag>
          </template>
          <template v-if="column.dataIndex == 'actions'">
            <a-space>
              <a-popconfirm
                title="确认删除跟记录吗？"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteMemo(record)"
                @cancel="showDeleteConfirm = false"
              >
                <a><MyIcon type="icon-delete" /></a>
              </a-popconfirm>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-spin>

    <a-modal
      v-model:visible="editModalVisible"
      footer=""
    >
      <template #title>
        新增备忘录
      </template>
      <a-form
        :model="editingMemo"
        name="editForm"
        ref=""
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        autocomplete="off"
        @finish="updateMemo"
      >
        <a-form-item
          label="类别"
        >
          <a-select
            style="width: 100%"
            v-model:value="editingMemo.type"
          >
            <a-select-option value="0">预订</a-select-option>
            <a-select-option value="1">售后</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
            name="id"
            label="内容"
          >
          <a-textarea
            v-model:value="editingMemo.content"
            :rows="5"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 6, span: 14 }">
          <a-button type="primary" html-type="submit">
            {{ $t('actions.submit') }}
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

</template>


<script>
import { defineComponent, onMounted, ref} from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";

import { storeToRefs } from 'pinia';
import { useMemosStore } from '@/stores';

export default defineComponent({
  props: ['current_order_no'],
  components: {},
  setup(props, ctx) {

    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);
    const formRef = ref(null);
    const importModalVisible = ref(false);

    const order_no = props.current_order_no;

    const selectedTravelerIndex = ref(null);

    const memosStore = useMemosStore();
    const {
      memos,
      spinningState,
      editingMemo,
      pagination,
      editModalVisible
    } = storeToRefs(memosStore);

    memosStore.searchState.order_no = props.current_order_no;

    const columns = [
      {
        title: '类型',
        dataIndex: 'type',
        width: '10%'
      },
      {
        title: '内容  ',
        dataIndex: 'content',
      },
      {
        title: '添加人',
        dataIndex: 'customer_service_name',
        width: '12%'
      },
      {
        title: "操作",
        dataIndex: 'actions',
        width: '10%'
      }
    ];

    // 初始化
    onMounted(() => {
      memosStore.fetchAll();
      memosStore.editingMemo = {};
    });

    const addMemo = () => {
      memosStore.editModalVisible = true;
      memosStore.editingMemo = {};
    }

    const updateMemo = () => {
      memosStore.update();
      message.success('更新成功');
    }

    const deleteMemo = (traveler) => {
      memosStore.delete(traveler);
    }

    const handleTableChange = (page, filters, sorter) => {
      memosStore.searchState.pageNum = page.current;
      memosStore.searchState.pageSize = page.pageSize;
      memosStore.fetchAll();
    };

    return {
      routeName,
      memos,
      columns,
      spinningState,
      editingMemo,
      editModalVisible,
      addMemo,
      updateMemo,
      deleteMemo,
      pagination,
      handleTableChange
    }
  },
});
</script>

<style lang="less">
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';

// 出行人员
export const useTravelersStore = defineStore({
  id: 'travelers',
  state: () => ({
    travelers: [],
    selectedTraveler: {},
    editingTraveler: {},
    loading: false,
    modalVisible: false,
    modalTitle: '',
    editModalVisible: false,
    travelersSpinning: false,
    formState: {
      id: '',
      name: '',
      id_card: '',
      phone: '',
      email: '',
      passport: '',
      gender: '',
      birthday: '',
      address: '',
      nationality: ''
    },
    searchState: {
      pageNum: 1,
      pageSize: 30,
      order_no: '',
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    spinningState: {
      editTravelerSpinning: false,
      addTravelerSpinning: false,
      deleteTravelerSpinning: false,
    },
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchAll() {
      this.travelersSpinning = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/traveler`, this.searchState)
        .then(response => response)
        .then(data => {
          if (data.code === 520) {
            this.travelers = data.list.data || [];
            this.searchState.total = data.list.total || 0;
            this.pagination = this.computedPagination;
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.travelersSpinning = false;
        });
    },

    async update() {
      this.spinningState.editTravelerSpinning = true;

      this.editingTraveler.order_no = this.searchState.order_no;

      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/updateTraveler`, this.editingTraveler)
        .then(response => response.data)
        .then(data => {
          if (data.code === 520) {
            console.log('Traveler updated!');
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.fetchAll();
          this.editModalVisible = false;
          this.spinningState.editTravelerSpinning = false;
        });
    },

    async delete(params) {
      this.spinningState.deleteTravelerSpinning = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/deleteTraveler`, params)
        .then(response => response.data)
        .then(data => {
          if (data.code === 520) {
            console.log('Traveler deleted!');
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.fetchAll();
          this.spinningState.deleteTravelerSpinning = false;
        });
    },

  }
});

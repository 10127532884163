<template>
<div>
  <a-row class="page-header mb-3">
    <a-col :span="24">
      <h2>{{routeName ? $t(routeName) : "Untitled"}}</h2>
    </a-col>
  </a-row>

  <a-row :gutter="24">
    <a-col :span="24">
      <a-row class="page-search mb-4">
        <a-col :span="24">
          <a-form
            layout="inline"
            :model="searchState"
            @finish="search"
          >
            <a-form-item class="search-form-item">
              <a-range-picker
                :locale="locale"
                v-model:value="searchState.book_date"
                :placeholder="['下单日期起', '下单日期止']"
                :format="dateFormat"
              />
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-range-picker
                :locale="locale"
                v-model:value="searchState.travel_date"
                :placeholder="['出行日期起', '出行日期止']"
                :format="dateFormat"
              />
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 120px"
                placeholder="来源"
                allowClear="true"
                v-model:value="searchState.source"
                :options="sources.map(item => ({label: item.label, value: item.value}))"
              >
              </a-select>
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-input v-model:value="searchState.ota_order_no" placeholder="OTA平台订单号" allowClear="true"></a-input>
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-input
                v-model:value="searchState.taobao_buyer_nick"
                style="width: 150px"
                placeholder="买家ID"
                allowClear="true"
              />
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-input
                v-model:value="searchState.name"
                style="width: 150px"
                placeholder="姓名"
                allowClear="true"
              />
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-input
                v-model:value="searchState.phone"
                style="width:150px"
                placeholder="手机号"
                allowClear="true"
              />
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-input
                v-model:value="searchState.email"
                style="width:220px;"
                placeholder="Email"
                allowClear="true"
              />
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 180px"
                placeholder="供应商"
                allowClear="true"
                v-model:value="searchState.supplier_id"
                :options="suppliers.map(item => ({label: item.supplier_name, value: item.id}))"
              >
              </a-select>
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 120px"
                placeholder="产品分类"
                allowClear="true"
                v-model:value="searchState.product_category_id"
                :options="productCategories.map(item => ({label: item.name, value: item.id}))"
              >
              </a-select>
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 220px"
                placeholder="产品"
                allowClear="true"
                v-model:value="searchState.product_code"
              >
                <a-select-option
                  v-for="product in products"
                  :key="product.code"
                  :value="product.code"
                  :label="getProductLabel(product)"
                >
                  {{ product.name }} <br/> {{ product.supplier.supplier_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 180px"
                placeholder="客服"
                allowClear="true"
                v-model:value="searchState.customer_service_id"
                :options="accounts.map(item => ({label: item.username, value: item.id}))"
              >
              </a-select>
            </a-form-item>
            <a-form-item class="search-form-item">
              <a-space>
                <a-button type="text" @click="advancedSearch">
                  更多条件
                  <a-badge v-if="countAdvancedSearch > 0" :count="countAdvancedSearch" style="margin-left:3px;" :number-style="{ backgroundColor: '#52c41a' }" />
                </a-button>
                <a-button type="default" html-type="submit">
                  <MyIcon type="icon-search1" />
                  {{ $t('actions.search') }}
                </a-button>
                <a-button type="default" @click="()=>{this.$router.go(0);}">
                  <MyIcon type="icon-reload" />
                  {{ $t('actions.reset') }}
                </a-button>
                <a-button type="default" @click="openExportModal">
                  <MyIcon type="icon-push" />
                  导出
                </a-button>
                <a-button type="default" @click="syncTmallOrder">
                  <MyIcon type="icon-ic24-sync" />
                  手工同步
                </a-button>
                <a-button type="primary" @click="newBooking" class="ml-3">
                  <MyIcon type="icon-iconfontjiahao1" />
                  {{ $t('actions.add') }}
                </a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-row v-if="hasSelected" class="mb-2">
        <a-col>
          <a-space>
            <a-button @click="openBatchAssignModal">批量指派</a-button>
            <a-button @click="openBatchTagModal">批量打标签</a-button>
          </a-space>
        </a-col>
      </a-row>

      <a-table
        size="small"
        :columns="columns"
        :data-source="bookings"
        :loading="loading"
        :scroll="{ x: 1500}"
        :pagination="pagination"
        row-key="order_no"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
      >
        <template #bodyCell="{ text, record, column }">
          <template v-if="column.dataIndex == 'status'">
            <a-space direction="vertical">
              <a-tag v-if="record.is_canceled == 1" color="#888888">已退订</a-tag>
              <a-tag v-if="record.is_closed == 1" color="#aaaaaa">已关闭</a-tag>
              <a-tag v-if="record.is_sent_email == 1" color="#87d068">已发函</a-tag>
              <a-tag v-if="record.is_completed == 1" color="#87d068">已完成</a-tag>
              <a-tag v-if="record.is_insurance == 1" color="#87d068">已保险</a-tag>
              <a-tag v-if="record.is_abnormal == 1" color="#ffd654">异常单</a-tag>
              <a-tag v-if="record.is_after_sales == 1" color="#e67781">售后单</a-tag>
            </a-space>
          </template>
          <template v-if="column.dataIndex == 'store'">
            <a @click="editRecord(record)">
              {{ text?.store_name }}
            </a>
          </template>
          <template v-if="column.dataIndex =='tmall_orders'">
            <a-space direction="vertical">
              <a-tag v-for="(order, index) in text" :key="index">
                {{ order.tmall_order_id }}
              </a-tag>
            </a-space>
          </template>
          <template v-if="column.dataIndex == 'project'">
            <a @click="editRecord(record)">
              <div>
                <strong>
                  {{ record.project_name }}
                </strong>
              </div>
              <div>
                <a-typography-text type="secondary">{{ record.supplier.supplier_name }}</a-typography-text>
              </div>
            </a>
          </template>
          <template v-if="column.dataIndex == 'booker'">
            <div>{{ record.name }}</div>
            <!-- <div>手机：{{ record.phone }}</div> -->
          </template>
          <template v-if="column.dataIndex == 'cost'">
            <div>
              {{ (record.adult_cost * record.adult_num).toFixed(2) }} / 
              {{ (record.child_cost * record.child_num).toFixed(2) }} / 
              {{ (record.unit_cost * record.unit_num).toFixed(2) }} / 
              {{ record.transfer_cost }}</div>
          </template>
          <template v-if="column.dataIndex == 'people_num'">
            <div>{{ record.adult_num }} / {{ record.child_num }} / {{ record.infant_num }}</div>
          </template>
          <template v-if="column.dataIndex == 'actions'">
            <a-space>
              <a-button @click="editRecord(record)" ><MyIcon type="icon-push"/>详情</a-button>
              <!-- <a-dropdown>
                <template #overlay>
                  <a-menu @click="handleMenuClick">
                    <a-menu-item key="1">操作1</a-menu-item>
                    <a-menu-item key="2">操作2</a-menu-item>
                    <a-menu-item key="3">操作3</a-menu-item>
                  </a-menu>
                </template>
                <a-button>
                  更多
                  <MyIcon type="icon-arrow-down" />
                </a-button>
              </a-dropdown> -->
            </a-space>
          </template>
        </template>

        <template #summary>
          <a-table-summary-row>
            <a-table-summary-cell :col-span="7" style="text-align:right">
              统计：
            </a-table-summary-cell>
            <a-table-summary-cell>
              <a-typography-text>
                {{ summary.summary_adult }} / {{ summary.summary_child }} / {{ summary.summary_infant }}
              </a-typography-text>
            </a-table-summary-cell>
            <a-table-summary-cell>
              <a-typography-text>{{ summary.summary_unit }}</a-typography-text>
            </a-table-summary-cell>
            <a-table-summary-cell>
              <a-typography-text>{{ summary.summary_actual }}</a-typography-text>
            </a-table-summary-cell>
            <a-table-summary-cell :col-span="4" style="text-align:center">
              <a-typography-text >{{ summary.summary_cost }}</a-typography-text>
            </a-table-summary-cell>
            <a-table-summary-cell :col-span="2" style="text-align:center">
              <a-typography-text ></a-typography-text>
            </a-table-summary-cell>
          </a-table-summary-row>
        </template>
      </a-table>
    </a-col>
  </a-row>

  <!-- 导出 -->
  <a-modal
    v-model:open="exportModalVisible"
    title="导出"
    :maskClosable=false
    :footer="null"
  >
    <div style="min-height:180px;">
      <a-row>
        <a-col :span="24">
          <a-space>
            <a-button type="default" @click="exportBookings">导出订单</a-button>
            <a-button type="default" @click="exportTravelers">导出出行人</a-button>
          </a-space>
        </a-col>
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <a-list>
            <a-list-item v-for="item in exportResult" :key="item.key">
              <a :href="item.url" target="_blank">{{ item.key }}</a>
            </a-list-item>
          </a-list>
        </a-col>
      </a-row>
    </div>
  </a-modal>

  <!-- 手工同步订单 -->
  <a-modal
    v-model:open="syncModalVisible"
    title="同步飞猪订单"
    :maskClosable="false"
    :footer="null"
  >
    <div v-if="syncModalVisible">
      <SyncOrder />
    </div>
  </a-modal>

  <!-- 新增预订 -->
  <a-modal
    v-model:open="newModalVisible"
    v-model:title="newModalTitle"
    :maskClosable=false
    :footer="null"
  >
    <a-form
      :model="newBookingState"
      name="newBookingForm"
      ref="newBookingFormRef"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="finishNewBooking"
    >
      <a-form-item
        label="产品"
        name="product_id"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-select
          show-search
          optionFilterProp="label"
          allowClear
          v-model:value="newBookingState.product_id"
          @change="handleProductChange"
        >
          <a-select-option
            v-for="product in products"
            :key="product.id"
            :value="product.id"
            :label="getProductLabel(product)"
          >
            {{ product.name }} <br/> {{ product.supplier.supplier_name }}
            <!-- <div>
              <h4>{{ product.name }}</h4>
              <div>{{ product.supplier.supplier_name }}</div>
              <a-divider dashed="true" style="margin:3px 0;padding:0;"/>
            </div> -->
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        label="OTA平台"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-input v-model:value="newBookingState.ota_order_no">
          <template #addonBefore>
            <a-select
              show-search
              optionFilterProp="label"
              style="width: 100px"
              v-model:value="newBookingState.source"
              :options="sources.map(item => ({label: item.label, value: item.value}))"
            >
            </a-select>
          </template>
        </a-input>
      </a-form-item>


      <a-form-item
        name="taobao_buyer_nick"
        label="淘宝昵称"
        :rules="[{ required: false }]"
      >
        <a-input v-model:value="newBookingState.taobao_buyer_nick" placeholder="淘宝昵称" />
      </a-form-item>

      <a-form-item
        label="预订数量"
        style="padding: 0; margin: 0;"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-row :gutter="12" v-if="product.billing_method == 'by_crowd'">
          <a-col :span="12">
            <a-form-item
              name="adult_num"
              :rules="[{ required: product.billing_method == 'by_crowd', message: '必填项目' }]"
            >
              <a-input-number style="width:100%" v-model:value="newBookingState.adult_num" placeholder="成人数量" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12" v-if="product.billing_method == 'by_crowd'">
          <a-col :span="12">
            <a-form-item
              name="child_num"
              :rules="[{ required: product.billing_method == 'by_crowd', message: '必填项目' }]"
            >
              <a-input-number style="width:100%"  name="child_cost" v-model:value="newBookingState.child_num" placeholder="儿童数量" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12" v-else>
          <a-col :span="12">
            <a-form-item
              name="unit_num"
              :rules="[{ required: product.billing_method == 'by_crowd', message: '必填项目' }]"
            >
              <a-input-number style="width:100%" v-model:value="newBookingState.unit_num" placeholder="数量" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item
        name="actual_amount"
        label="实付金额"
        :rules="[{ required: product.billing_method == 'by_crowd', message: '必填项目' }]"
      >
        <a-row :gutter="12">
          <a-col :span="12">
            <a-input-number
              v-model:value="newBookingState.actual_amount"
              style="width: 100%"
              :min="0"
              :max="100000"
              :step="0.01"
              prefix="￥"
              string-mode
            />
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 6, span: 16 }">
        <a-button type="primary" html-type="submit">
          {{ $t('actions.submit') }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>

  <!-- 编辑预订 -->
  <a-drawer
    v-model:open="modalVisible"
    v-model:title="modalTitle"
    :maskClosable=false
    width="1080px"
  >
    <template #extra>
      <a-button type="primary" @click="validateForm">提交保存</a-button>
    </template>
    <a-spin :spinning="spinningState.editBookingSpinning">

      <a-descriptions
        size="small"
      >
        <template #title>
          <a-row>
            <a-col :span="12">
              <div class="projectname">
                {{ bookingDetail.product?.name }}
              </div>
            </a-col>
            <a-col :span="12" style="text-align:right">
              <a-space>
                <a-button @click="openEmailModal">
                  <a-space>
                    <MyIcon type="icon-email"/>
                    邮件发单
                    <a-badge :count="bookingDetail.total_mails" :number-style="{ backgroundColor: '#52c41a' }"/>
                  </a-space>
                </a-button>
                <a-button @click="openMemoModal">
                  <a-space>
                    <MyIcon type="icon-weimingmingwenjianjia_beiwanglu"/>
                    备忘录
                    <a-badge :count="bookingDetail.total_memos" :number-style="{ backgroundColor: '#52c41a' }"/>
                  </a-space>
                </a-button>
                <a-button @click="openRelatedModal">
                  <a-space>
                    <MyIcon type="icon-ic24-sync"/>
                    关联订单
                    <a-badge :count="bookingDetail.total_relateds" :number-style="{ backgroundColor: '#52c41a' }"/>
                  </a-space>
                </a-button>
                <a-button @click="copyBooking">
                  <a-space>
                    <MyIcon type="icon-copy"/>
                    复制订单
                  </a-space>
                </a-button>
              </a-space>
            </a-col>
          </a-row>
        </template>

        <a-descriptions-item label="订单编号">
          <a-typography-paragraph copyable>
            {{ bookingDetail.order_no }}
          </a-typography-paragraph>
        </a-descriptions-item>
        <a-descriptions-item label="买家ID"><a-typography-paragraph v-model:content="bookingDetail.taobao_buyer_nick" editable /></a-descriptions-item>
        <a-descriptions-item label="更新时间">{{ bookingDetail.update_time }}</a-descriptions-item>
        <a-descriptions-item label="订单来源">
          <span style="margin-right: 10px">
            {{ bookingDetail.store?.store_name }}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label="关联订单">
          <a-space size="10" direction="vertical">
            <a-typography-paragraph v-for="(order, index) in bookingDetail.tmall_orders" :key="index" copyable>
              <a-tag >
                {{ order.tmall_order_id }}
              </a-tag>
            </a-typography-paragraph>
          </a-space>
        </a-descriptions-item>
        <a-descriptions-item label="跟单客服">
          {{ bookingDetail.customer_service_name }}
        </a-descriptions-item>
      </a-descriptions>
      <div class="mt-2 mb-4"></div>
      <div>
        <a-row>
          <a-col :span="18">
            <a-space>
              <a-checkable-tag class="checktag" v-model:checked="bookingDetail.is_canceled" @change="changeTags('is_canceled')" color="#88888">已退订</a-checkable-tag>
              <a-checkable-tag class="checktag" v-model:checked="bookingDetail.is_closed" @change="changeTags('is_closed')" color="#aaaaaa">已关闭</a-checkable-tag>
              <a-checkable-tag class="checktag" v-model:checked="bookingDetail.is_sent_email" @change="changeTags('is_sent_email')" color="#87d068">已发函</a-checkable-tag>
              <a-checkable-tag class="checktag" v-model:checked="bookingDetail.is_completed" @change="changeTags('is_completed')" color="#87d068">已完成</a-checkable-tag>
              <a-checkable-tag class="checktag" v-model:checked="bookingDetail.is_abnormal" @change="changeTags('is_abnormal')"  color="#ffd654">异常单</a-checkable-tag>
              <a-checkable-tag class="checktag" v-model:checked="bookingDetail.is_after_sales" @change="changeTags('is_after_sales')"  color="#e67781">售后单</a-checkable-tag>
              <a-checkable-tag class="checktag" v-model:checked="bookingDetail.is_insurance" @change="changeTags('is_insurance')" color="#87d068">已保险</a-checkable-tag>
            </a-space>
          </a-col>
          <a-col :span="6" class="text-right">
            
          </a-col>
        </a-row>
      </div>

      <a-form
        :model="bookingDetail"
        name="editForm"
        ref="formRef"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
        @submit="finishRecordForm"
      >

      <a-divider>预订产品</a-divider>

      <a-row :gutter="12">
        <a-col :span="12">
          <a-form-item
            label="预订产品"
          >
            <strong>{{ bookingDetail.product?.name }}</strong>
            <a-typography-text type="secondary" style="margin-left:10px;">{{ bookingDetail.supplier?.supplier_name }}</a-typography-text>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="变更"
          >
            <a-select
              show-search
              optionFilterProp="label"
              style="width: 100%"
              @select="onProductChanged"
              v-model:value="bookingDetail.product_code"
            >
              <a-select-option
                v-for="product in products"
                :key="product.code"
                :value="product.code"
                :label="getProductLabel(product)"
              >
                {{ product.name }} <br/> {{ product.supplier.supplier_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider>出行信息</a-divider>

        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item
              label="预订日期"
              name="book_date"
              :rules="[{ required: true, message: '必填项目' }]"
            >
              <a-date-picker
                v-model:value="formattedBookDate"
                :format="dateFormat"
                :locale="locale"
                class="input-width"
              />
            </a-form-item>

            <a-form-item
              label="出行日期"
              name="travel_date"
              :rules="[{ required: true, message: '必填项目' }]"
            >
              <a-date-picker
                v-model:value="formattedTravelDate"
                :format="dateFormat"
                :locale="locale"
                class="input-width"
              />
            </a-form-item>

            <a-form-item
              label="预订数量"
              style="padding: 0; margin: 0;"
              :rules="[{ required: true, message: '必填项目' }]"
            >
              <a-row :gutter="12" v-if="bookingDetail.product?.billing_method == 'by_crowd'">
                <a-col :span="8">
                  <a-form-item
                    name="adult_num"
                    :rules="[{ required: false }]"
                  >
                    <a-input v-model:value="bookingDetail.adult_num" placeholder="成人"  />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    name="child_num"
                    :rules="[{ required: false }]"
                  >
                    <a-input name="child_cost" v-model:value="bookingDetail.child_num" placeholder="儿童"  />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    name="infant_num"
                    :rules="[{ required: bookingDetail.product?.billing_method == 'by_unit', message: '必填项目' }]"
                  >
                    <a-input v-model:value="bookingDetail.infant_num" placeholder="婴儿" />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row :gutter="12" v-else>
                <a-col :span="8">
                  <a-form-item
                    name="unit_num"
                    :rules="[{ required: false }]"
                  >
                    <a-input v-model:value="bookingDetail.unit_num" placeholder="数量" class="input-width" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              label="接人酒店"
              name="ota_order_no"
              :rules="[{ required: false }]"
            >
              <a-textarea v-model:value="bookingDetail.pickup_hotel" />
            </a-form-item>

            <a-form-item
              label="送回酒店"
              name="ota_order_no"
              :rules="[{ required: false }]"
            >
              <a-textarea v-model:value="bookingDetail.return_hotel" />
            </a-form-item>

            <a-form-item
              label="行程备注"
              name="remark"
              :rules="[{ required: false }]"
            >
              <a-textarea
                rows="3"
                v-model:value="bookingDetail.remark"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="预订人"
              style="margin:0; padding: 0;"
              :rules="[{ required: true, message: '必填项目' }]"
            >
              <a-row :gutter="12">
                <a-col :span="9">
                  <a-form-item
                    name="name"
                    :rules="[{ required: true, message: '必填项目' }]"
                  >
                    <a-input @change="changeName" v-model:value="bookingDetail.name" placeholder="姓名" />
                  </a-form-item>
                </a-col>
                <a-col :span="15">
                  <a-form-item
                    name="name_pinyin"
                    :rules="[{ required: true, message: '必填项目' }]"
                  >
                    <a-input v-model:value="bookingDetail.name_pinyin" placeholder="拼音姓名" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>

            <a-form-item
              label="电话"
              name="phone"
              :rules="[{ required: false, message: '' }]"
            >
              <a-input v-model:value="bookingDetail.phone" />
            </a-form-item>

            <a-form-item
              label="邮箱"
              name="email"
              :rules="[{ required: false, message: '' }]"
            >
              <a-input v-model:value="bookingDetail.email" />
            </a-form-item>

            <a-form-item
              label="微信"
              name="wechat"
              :rules="[{ required: false }]"
            >
              <a-input v-model:value="bookingDetail.wechat" />
            </a-form-item>

            <a-form-item
              label="国外电话"
              name="overseas_phone"
              :rules="[{ required: false }]"
            >
              <a-input v-model:value="bookingDetail.overseas_phone" />
            </a-form-item>

          </a-col>
        </a-row>

        <!-- <a-divider>附加项目</a-divider> -->

        <div v-if="current_order_no != ''">
          <Travelers :current_order_no="current_order_no" :key="current_order_no"/>
        </div>

        <a-divider>订单金额</a-divider>
        <a-row :gutter="12">
          <a-col :span="8">
            <a-form-item
              label="实付金额"
              name="actual_amount"
              :rules="[{ required: false, message: '必填项目' }]"
            >
              <a-input-number
                class="input-width"
                :min="0"
                :max="100000"
                :step="0.01"
                prefix="￥"
                v-model:value="bookingDetail.actual_amount"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="附加费"
              name="transfer_cost"
              :rules="[{ required: false, message: '必填项目' }]"
            >
              <a-input-number
                class="input-width"
                :min="0"
                :max="100000"
                :step="0.01"
                prefix="￥"
                v-model:value="bookingDetail.transfer_cost"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="退款金额"
              name="refund_amount"
              :rules="[{ required: false}]"
            >
              <a-input-number
                class="input-width"
                :min="0"
                :max="100000"
                :step="0.01"
                prefix="￥"
                v-model:value="bookingDetail.refund_amount"
              />
            </a-form-item>
          </a-col>
        </a-row>


        <!-- <div style="margin-top: 30px; margin-bottom: 30px; width: '100%';">
          <a-button type="primary" html-type="submit">
            {{ $t('actions.submit') }}
          </a-button>
        </div> -->

      </a-form>
    </a-spin>
  </a-drawer>

  <!-- Advanced Search -->
  <a-modal
    v-model:open="advancedModalVisible"
    title="更多条件"
    :maskClosable=true
    :footer="null"
  >
    <a-form
      :model="searchState"
      name="advancedSearchForm"
      ref="advanceSearchFormRef"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="finishNewBooking"
    >
      <a-form-item
        label="关闭状态"
        name="is_closed"
      >
        <div>
          <a-radio-group v-model:value="searchState.is_closed" button-style="solid" @change="advancedSearchChanged">
            <a-radio-button value="-1">不选择</a-radio-button>
            <a-radio-button value="1">已关闭</a-radio-button>
            <a-radio-button value="0">未关闭</a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>

      <a-form-item
        label="退订状态"
        name="is_canceled"
      >
        <div>
          <a-radio-group v-model:value="searchState.is_canceled" button-style="solid" @change="advancedSearchChanged">
            <a-radio-button value="-1">不选择</a-radio-button>
            <a-radio-button value="1">已退订</a-radio-button>
            <a-radio-button value="0">未退订</a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>

      <a-form-item
        label="异常状态"
        name="is_abnormal"
      >
        <div>
          <a-radio-group v-model:value="searchState.is_abnormal" button-style="solid" @change="advancedSearchChanged">
            <a-radio-button value="-1">不选择</a-radio-button>
            <a-radio-button value="1">异常单</a-radio-button>
            <a-radio-button value="0">非异常</a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>

      <a-form-item
        label="完成状态"
        name="is_completed"
      >
        <div>
          <a-radio-group v-model:value="searchState.is_completed" button-style="solid" @change="advancedSearchChanged">
            <a-radio-button value="-1">不选择</a-radio-button>
            <a-radio-button value="1">已完成</a-radio-button>
            <a-radio-button value="0">未完成</a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>

      <a-form-item
        label="售后状态"
        name="is_after_sales"
      >
        <div>
          <a-radio-group v-model:value="searchState.is_after_sales" button-style="solid" @change="advancedSearchChanged">
            <a-radio-button value="-1">不选择</a-radio-button>
            <a-radio-button value="1">售后单</a-radio-button>
            <a-radio-button value="0">非售后</a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>

      <a-form-item
        label="邮件状态"
        name="is_sent_email"
      >
        <div>
          <a-radio-group v-model:value="searchState.is_sent_email" button-style="solid" @change="advancedSearchChanged">
            <a-radio-button value="-1">不选择</a-radio-button>
            <a-radio-button value="1">已发送</a-radio-button>
            <a-radio-button value="0">未发送</a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>

      <a-form-item
        label="保险状态"
        name="is_insurance"
      >
        <div>
          <a-radio-group v-model:value="searchState.is_insurance" button-style="solid" @change="advancedSearchChanged">
            <a-radio-button value="-1">不选择</a-radio-button>
            <a-radio-button value="1">已保险</a-radio-button>
            <a-radio-button value="0">未保险</a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>


  <!-- 批量指派 -->
  <a-modal
    v-model:open="assignModalVisible"
    title="批量指派"
    :maskClosable=false
    :footer="null"
  >
    <a-form
      :model="batchAssignState"
      name="batchAssignForm"
      ref=""
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="finishBatchAssign"
    >
      <a-form-item
        label="客服"
        name="customer_service_id"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-select
          show-search
          optionFilterProp="label"
          v-model:value="batchAssignState.customer_service_id"
          :options="accounts.map(item => ({label: item.username, value: item.id}))"
        >
        </a-select>
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 6, span: 16 }">
        <a-button type="primary" html-type="submit">
          {{ $t('actions.submit') }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>

  <!-- 批量打标签 -->
  <a-modal
    v-model:open="tagModalVisible"
    title="批量打标签"
    :maskClosable=false
    :footer="null"
  >
    <a-form
      :model="batchTagState"
      name="batchTagForm"
      ref=""
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="finishBatchTag"
    >
      <a-form-item
        label="保险状态"
        name="is_insurance"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-select
          show-search
          optionFilterProp="label"
          v-model:value="batchTagState.is_insurance"
        >
          <a-select-option value="0">未买保险</a-select-option>
          <a-select-option value="1">已买保险</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 6, span: 16 }">
        <a-button type="primary" html-type="submit">
          {{ $t('actions.submit') }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-drawer
    v-model:open="memoModalVisible"
    title="备忘录"
    size="large"
    footer=""
  >
    <div v-if="memoModalVisible && current_order_no != '' && bookingDetail">
      <Memos :current_order_no="current_order_no" :key="current_order_no"/>
    </div>
  </a-drawer>

  <a-drawer
    v-model:open="emailModalVisible"
    title="邮件发单"
    size="large"
    footer=""
  >
    <div v-if="emailModalVisible && current_order_no != '' && bookingDetail">
      <Mails :current_order_no="current_order_no" :current_booking_detail="bookingDetail" :key="current_order_no"/>
    </div>
  </a-drawer>

  <a-drawer
    v-model:open="relatedModalVisible"
    title="关联订单"
    size="large"
  >
    <div v-if="relatedModalVisible && current_order_no != '' && bookingDetail">
      <RelatedBookings :current_order_no="current_order_no" :current_booking_detail="bookingDetail" :key="current_order_no"/>
    </div>
  </a-drawer>

</div>
</template>

<script>
import { defineComponent, computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { message, Modal } from 'ant-design-vue';
import { useI18n } from "vue-i18n";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
dayjs.locale('zh-cn');
import Pinyin from 'pinyin';

import { storeToRefs } from 'pinia';
import {
  useBookingsStore,
  useSuppliersStore,
  useProductsStore,
  useProductCategoriesStore,
  useAccountsStore
} from "@/stores";


import SyncOrder from './SyncOrder';
import Travelers from './Travelers';
import Memos from './Memos';
import Mails from './Mails';
import RelatedBookings from './RelatedBookings';

export default defineComponent({
  props: {
    msg: String,
  },
  components: {
    SyncOrder,
    Travelers,
    Memos,
    Mails,
    RelatedBookings
  },
  setup() {
    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);

    const countAdvancedSearch = ref(0);

    const bookingsStore = useBookingsStore();
    const {
      selectedRowKeys,
      spinningState,
      bookings,
      summary,
      current_order_no,
      newBookingState,
      searchState,
      formState,
      bookingDetail,
      pagination,
      loading,
      modalVisible,
      modalTitle,
      newModalVisible,
      newModalTitle,
      syncModalVisible,
      memoModalVisible,
      emailModalVisible,
      relatedModalVisible,
      assignModalVisible,
      tagModalVisible,
      advancedModalVisible,
      exportModalVisible,
      batchAssignState,
      batchTagState,
      exportResult
    } = storeToRefs(bookingsStore);

    const productsStore = useProductsStore();
    const { products, product } = storeToRefs(productsStore);
    productsStore.searchState.pageSize = 1000;
    productsStore.searchState.pageNum = 1;
    productsStore.fetchAll();

    const suppliersStore = useSuppliersStore();
    const { suppliers } = storeToRefs(suppliersStore);
    suppliersStore.searchState.pageSize = 100;
    suppliersStore.searchState.pageNum = 1;
    suppliersStore.fetchAll();

    const productCategoriesStore = useProductCategoriesStore();
    const { productCategories } = storeToRefs(productCategoriesStore);
    productCategoriesStore.searchState.pageSize = 100;
    productCategoriesStore.searchState.pageNum = 1;
    productCategoriesStore.fetchAll();

    const accountsStore = useAccountsStore();
    const { accounts } = storeToRefs(accountsStore);
    accountsStore.searchState.pageSize = 100;
    accountsStore.searchState.pageNum = 1;
    accountsStore.fetchAll();

    const dateFormat = 'YYYY-MM-DD';
    const formattedBookDate = computed({
      get: () => {
        if (!bookingsStore.bookingDetail.book_date) {
          return null;
        }
        return dayjs(bookingsStore.bookingDetail.book_date);
      },
      set: (newValue) => {
        bookingsStore.bookingDetail.book_date = newValue.format(dateFormat);
      }
    });

    const formattedTravelDate = computed({
      get: () => {
        if (!bookingsStore.bookingDetail.travel_date) {
          return null;
        }
        return dayjs(bookingsStore.bookingDetail.travel_date);
      },
      set: (newValue) => {
        bookingsStore.bookingDetail.travel_date = newValue.format(dateFormat);
      }
    });

    const columns = [
      {
        title: "状态",
        dataIndex: "status",
        width: 100
      },
      {
        title: '关联订单',
        dataIndex: "tmall_orders",
        width: 200
      },
      {
        title: '预订项目',
        dataIndex: "project",
        width: 400
      },
      {
        title: '预订日期',
        dataIndex: "book_date",
        width: 150
      },
      {
        title: '出行日期',
        dataIndex: 'travel_date',
        width: 150
      },
      {
        title: '买家ID',
        dataIndex: 'taobao_buyer_nick',
        width: 150
      },
      {
        title: '预订人',
        dataIndex: 'booker',
        width: 150
      },
      {
        title: '人数',
        dataIndex: 'people_num',
        width: 150
      },
      {
        title: '数量',
        dataIndex: 'unit_num',
        width: 80
      },
      {
        title: '实付金额',
        dataIndex: 'actual_amount',
        width: 100
      },
      {
        title: '客服',
        dataIndex: 'customer_service_name',
        width: 180
      },
      {
        title: '订单来源',
        dataIndex: "store",
        width: 300
      },
      {
        title: t('fields.actions'),
        dataIndex: "actions",
        fixed: 'right',
        width: 100
      }
    ];

    const sources = [
      { value: 1, label: '飞猪' },
      { value: 2, label: '携程' },
      { value: 3, label: '马蜂窝' },
      { value: 4, label: '美团' },
      { value: 5, label: '抖音' },
      { value: 6, label: '微信' },
      { value: 98, label: '分销' },
      { value: 99, label: '其他' }
    ];


    // 初始化
    const storedAccount = localStorage.getItem("account");
    const accountData = JSON.parse(storedAccount);
    if (Number(accountData.tenant_id) == 393) {
      console.log(accountData)
      bookingsStore.searchState.is_closed = '0';
      bookingsStore.searchState.is_canceled = '0';
    }
    bookingsStore.fetchAll();

    // 搜索
    const search = () => {
      bookingsStore.searchState.mode = 'new';
      bookingsStore.searchState.pageNum = 1;
      bookingsStore.fetchAll();
    }

    const advancedSearchChanged = () => {
      bookingsStore.fetchAll();

      countAdvancedSearch.value = 5;
      let s = bookingsStore.searchState;
      countAdvancedSearch.value = 0;
      if (s.is_closed > -1) {
        countAdvancedSearch.value += 1;
      }
      if (s.is_canceled > -1) {
        countAdvancedSearch.value += 1;
      }
      if (s.is_abnormal > -1) {
        countAdvancedSearch.value += 1;
      }
      if (s.is_completed > -1) {
        countAdvancedSearch.value += 1;
      }
      if (s.is_after_sales > -1) {
        countAdvancedSearch.value += 1;
      }
      if (s.is_sent_email > -1) {
        countAdvancedSearch.value += 1;
      }
      if (s.is_insurance > -1) {
        countAdvancedSearch.value += 1;
      }
    }

    const formRef = ref();
    const newBookingFormRef = ref();

    const handleTableChange = (page, filters, sorter) => {
      bookingsStore.searchState.pageNum = page.current;
      bookingsStore.searchState.pageSize = page.pageSize;
      bookingsStore.fetchAll();
    };

    const handleProductChange = value => {
      productsStore.fetchOne(value);
    }

    // 新增订单
    const newBooking = () => {
      bookingsStore.newModalVisible = true;
      bookingsStore.newModalTitle = '新增预订';
      for (let key in bookingsStore.newBookingState) {
        bookingsStore.newBookingState[key] = '';
      }
    }

    const syncTmallOrder = () => {
      bookingsStore.syncModalVisible = true;
    }

    // 创建记录表单
    const createRecord = () => {
      bookingsStore.modalVisible = true;
      bookingsStore.modalTitle = t('actions.add') + t('menu.bookings');
      for (let key in productsStore.formState) {
        bookingsStore.formState[key] = '';
      }
    }

    // 编辑订单
    const editRecord = (record) => {
      bookingsStore.modalVisible = true;
      bookingsStore.modalTitle = t('actions.edit') + t('menu.bookings');
      bookingsStore.detail(record.order_no);
    }

    // 编辑订单表单验证
    function validateForm() {
      formRef.value.validate().then(() => {
        finishRecordForm();
      }).catch(() => {
        message.error('请先完善必填项目');
      });
    }

    // 编辑订单提交
    async function finishRecordForm() {
      await bookingsStore.update();
    };

    // 新增预订表单提交
    async function finishNewBooking() {
      await bookingsStore.add()
      editRecord({ order_no: bookingsStore.current_order_no });
    }

    const openMemoModal = () => {
      bookingsStore.memoModalVisible = true;
    }

    const openRelatedModal = () => {
      bookingsStore.relatedModalVisible = true;
    }

    const openEmailModal = () => {
      bookingsStore.emailModalVisible = true;
    }

    // 预订列表操作
    const hasSelected = computed(() => bookingsStore.selectedRowKeys.length > 0);
    const onSelectChange = rowKeys => {
      console.log('selectedRowKeys changed: ', rowKeys);
      bookingsStore.selectedRowKeys = rowKeys;
    };

    // 批量派单
    const openBatchAssignModal = () => {
      bookingsStore.assignModalVisible = true;
    }

    const onBatchAssign = () => {
      bookingsStore.batchAssign();
    }

    const openBatchTagModal = () => {
      bookingsStore.tagModalVisible = true;
    }

    const onBatchTag = () => {
      bookinsStore.batchTag();
    }

    // 批量指派提交
    async function finishBatchAssign(values) {
      bookingsStore.batchAssign(values.customer_service_id);
    };

    // 批量打标签提交
    async function finishBatchTag() {
      bookingsStore.batchTag();
    };

    // 格式化产品查询列表
    const getProductLabel = (product) => {
      // 获取选项的文本内容，包含标题和代码
      const content = `${product.name} | ${product.code}`;
      // 创建一个包含选项文本内容的字符串
      const label = document.createElement('div');
      label.textContent = content;
      return label.outerHTML;
    }

    async function changeTags(tag) {
      await bookingsStore.changeTags(tag);
    }

    // Advanced Search
    const advancedSearch = () => {
      bookingsStore.advancedModalVisible = true;
    }

    const openExportModal = () => {
      bookingsStore.exportModalVisible = true;
    }

    const exportBookings = () => {
      bookingsStore.exportBookings();
    }

    const exportTravelers = () => {
      bookingsStore.exportTravelers();
    }

    const changeName = (e) => {
      const pinyin = Pinyin(bookingsStore.bookingDetail.name, {
          style: Pinyin.STYLE_NORMAL, // 设置拼音风格，STYLE_NORMAL 为普通风格，STYLE_TONE2 为带音调风格
          heteronym: false // 是否启用多音字处理，默认为 false
      }).map(item => item[0].toUpperCase() + item.slice(1));

      let name_pinyin = pinyin.join(' ');
      bookingsStore.bookingDetail.name_pinyin = name_pinyin;
    }

    // 变更预订产品
    const onProductChanged = (value) => {
      if (value != bookingsStore.bookingDetail.product.code) {
        // message.error('不一致' + value + ' ' + bookingsStore.bookingDetail.product.code);
        Modal.confirm({
          title: '确认操作',
          content: "注意：变更产品会改变订单的产品、供应商信息和成本金额；不支持不同产品类型（按人数、数量）之间变更；保存订单后生效。请谨慎操作！",
          okText: '确认',
          cancelText: '取消',
          onOk: onProductChangedConfirm
        });
      }
    }

    // 复制订单
    const copyBooking = () => {
      Modal.confirm({
        title: '确认操作',
        content: "注意：复制订单将会作为其他来源的新增订单，与飞猪订单没有关联；复制成功后直接打开新订单。",
        okText: '确认',
        cancelText: '取消',
        onOk: copyBookingConfirm
      });
    }

    const copyBookingConfirm = () => {
      bookingsStore.copyBooking();
    }

    // 确认变更产品
    const onProductChangedConfirm = () => {
      // message.success('Confirm');
    }

    return {
      routeName,
      spinningState,
      bookings,
      summary,
      current_order_no,
      newBookingState,
      bookingDetail,
      products,
      product,
      productCategories,
      suppliers,
      accounts,
      search,
      countAdvancedSearch,
      searchState,
      columns,
      pagination,
      handleTableChange,
      handleProductChange,
      loading,
      modalVisible,
      modalTitle,
      newModalVisible,
      newModalTitle,
      syncModalVisible,
      syncTmallOrder,
      advancedModalVisible,
      createRecord,
      editRecord,
      newBooking,
      formState,
      finishRecordForm,
      validateForm,
      finishNewBooking,
      formRef,
      newBookingFormRef,
      sources,
      openMemoModal,
      memoModalVisible,
      openEmailModal,
      emailModalVisible,
      openRelatedModal,
      relatedModalVisible,
      assignModalVisible,
      tagModalVisible,
      exportModalVisible,
      dayjs,
      formattedBookDate,
      formattedTravelDate,
      dateFormat,
      locale,
      hasSelected,
      onSelectChange,
      selectedRowKeys,
      onBatchAssign,
      openBatchAssignModal,
      openBatchTagModal,
      batchAssignState,
      batchTagState,
      finishBatchAssign,
      finishBatchTag,
      getProductLabel,
      changeTags,
      advancedSearch,
      advancedSearchChanged,
      openExportModal,
      exportResult,
      exportBookings,
      exportTravelers,
      changeName,
      onProductChanged,
      copyBooking
    }
  },
});
</script>

<style>
.status {
  display: block;
}
.input-width {
  width: 168px;
}
.page-search .search-form-item {
  margin-bottom: 20px;
}
.projectname {
  font-size: 18px;
}
.ant-tag {
  background-color: #def;
}
.ant-tag-checkable-checked {
  background-color: #00b96b;
}
/* ant-tag css-dev-only-do-not-override-fkwitv ant-tag-checkable ant-tag-checkable-checked */
</style>
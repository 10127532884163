<template>
  <div>
    <a-divider>出行人 <a-badge v-if="travelers.length > 0" :count="travelers.length" :number-style="{ backgroundColor: '#52c41a' }" /></a-divider>
    <a-spin :spinning="travelersSpinning">
      <a-row :gutter="12">
        <a-col v-for="(item, index) in travelers" :key="index">
          <a-card hoverable style="margin-bottom: 15px;">
            <template #actions>
              <a @click="selectTraveler(index)"><MyIcon type="icon-edit-square" /></a>
              <a-popconfirm
                title="确认删除该出行人吗？"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteTraveler(item)"
                @cancel="showDeleteConfirm = false"
              >
                <a><MyIcon type="icon-delete" /></a>
              </a-popconfirm>
            </template>
            <a-card-meta :title="item.name + ' ' + item.name_pinyin" >
              <template #description>
                <div>
                  <span v-if="item.id_type == 'passport'">
                    护照：{{ item.id_no }}
                  </span>
                  <span v-else>
                    身份证：{{ item.id_no }}
                  </span>
                </div>
                <div>电话：{{ item.mobile_no }}</div>
                <div>
                  性别：<span v-if="item.gender =='M'">男</span> <span v-if="item.gender == 'F'">女</span>
                </div>
                <div>出生日期：{{ item.birthday }}</div>
                <div>国籍：{{ item.nationality }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </a-col>
      </a-row>
    </a-spin>

    <div class="mt-2" style="overflow:hidden;">
      <span class="fl">
        <a-space>
          <a-button size="small" @click="addTraveler">{{ $t('actions.add') }}</a-button>
          <a-button size="small" @click="openImportModal">{{ $t('actions.import') }}</a-button>
        </a-space>
      </span>
    </div>

    <a-modal
      v-model:visible="importModalVisible"
      title="导入出行人"
      @ok="handleOk"
    >
      <a-textarea
        v-model:value="travelerContent"
        style="min-height: 400px;"
        placeholder="姓名：张三
姓名拼音：ZHANG SAN
性别：M
"
      ></a-textarea>
    </a-modal>

    <a-modal
      v-model:visible="editModalVisible"
      footer=""
    >
      <template #title>
        <!-- <span v-if="selectedTraveler.length == 0">新增</span><span v-else>编辑</span>联系人 -->
        编辑联系人
      </template>
      <a-form
        :model="editingTraveler"
        name="editForm"
        ref="editTravelerFormRef"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        autocomplete="off"
        @finish="updateTraveler"
      >
        <a-form-item
            name="name"
            label="姓名"
          >
          <a-input  v-model:value="editingTraveler.name"/>
        </a-form-item>
        <a-form-item
            name="id"
            label="拼音姓名"
          >
          <a-input  v-model:value="editingTraveler.name_pinyin"/>
        </a-form-item>
        <a-form-item
          label="性别"
        >
          <a-select
            style="width: 100%"
            v-model:value="editingTraveler.gender"
          >
            <a-select-option value="M">男</a-select-option>
            <a-select-option value="F">女</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="证件"
        >
          <a-input v-model:value="editingTraveler.id_no">
            <template #addonBefore>
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 90px"
                v-model:value="editingTraveler.id_type"
                placeholder="类型"
              >
                <a-select-option value="idcard">身份证</a-select-option>
                <a-select-option value="passport">护照</a-select-option>
              </a-select>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item
            name="id"
            label="电话"
          >
          <a-input v-model:value="editingTraveler.mobile_no" />
        </a-form-item>
        <a-form-item
            name="id"
            label="出生日期"
          >
          <a-input v-model:value="editingTraveler.birthday" />
        </a-form-item>
        <a-form-item
          name="nationality"
          label="国籍"
        >
          <a-input v-model:value="editingTraveler.nationality" />
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 6, span: 14 }">
          <a-button type="primary" html-type="submit">
            {{ $t('actions.submit') }}
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

</template>


<script>
import { defineComponent, computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";

import { storeToRefs } from 'pinia';
import { useTravelersStore } from "@/stores";

export default defineComponent({
  props: ['current_order_no'],
  components: {},
  setup(props, ctx) {

    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);
    const formRef = ref(null);
    const importModalVisible = ref(false);

    console.log(props, ctx);
    const order_no = props.current_order_no;

    const selectedTravelerIndex = ref(null);

    const travelersStore = useTravelersStore();
    const {
      travelers,
      travelersSpinning,
      selectedTraveler,
      editingTraveler,
      searchState,
      formState,
      pagination,
      loading,
      modalVisible,
      modalTitle,
      editModalVisible
    } = storeToRefs(travelersStore);

    travelersStore.searchState.order_no = props.current_order_no;

    // 初始化
    onMounted(() => {
      travelersStore.fetchAll();
    });

    const selectTraveler = (index) => {
      selectedTravelerIndex.value = index;
      travelersStore.selectedTraveler = travelersStore.travelers[index];
      if (index < travelersStore.travelers.length) {
        travelersStore.editingTraveler = { ...travelersStore.travelers[index]}
      } else {
        travelersStore.editingTraveler = null;
      }
      console.log('travelersStore.editingTraveler', travelersStore.editingTraveler);
      travelersStore.editModalVisible = true;
    }

    const addTraveler = () => {
      travelersStore.editModalVisible = true;
      travelersStore.editingTraveler = {};
    }

    const updateTraveler = () => {
      travelersStore.update();
      message.success('更新成功');
    }

    const deleteTraveler = (traveler) => {
      travelersStore.delete(traveler);
    }

    const openImportModal = () => {
      importModalVisible.value = true;
      travelerContent.value = '';
    }


    // 从剪切板导入
    const travelerContent = ref('');

    const handleOk = () => {
      splitTravelers();
    }

    const splitTravelers = () => {

      if (travelerContent.value.length == 0) {
        message.error('请填写导入内容');
      } else {
        let fieldPattern = /([\u4e00-\u9fa5]+)：(.+)\n?/g;
        let matches = [], data = {}, match;
        while (match = fieldPattern.exec(travelerContent.value)) {
          const fieldName = match[1];
          const fieldValue = match[2];
          if (fieldName === '姓名') {
            if (Object.keys(data).length > 0) {
              // let obj = {fieldName: fieldValue};
              matches.push(data);
            }
            data = {};
          }
          data[fieldName] = fieldValue;
        }

        if (Object.keys(data).length > 0) {
          matches.push(data);
        }

        // 添加到travelers
        matches.map( traveler => {
          let newTraveler = {};
          for (let key in traveler) {
            if (key == '姓名') {
              newTraveler['name'] = traveler[key];
            }else if(key == '姓名拼音') {
              newTraveler['name_pinyin'] = traveler[key].replace("/", " ").toUpperCase();
            }else if(key == '性别') {
              newTraveler['gender'] = traveler[key];
            }else if(key == '护照') {
              newTraveler['id_type'] = 'passport';
              newTraveler['id_no'] = traveler[key].toUpperCase();
            }else if(key == '身份证') {
              newTraveler['id_type'] = 'idcard';
              newTraveler['id_no'] = traveler[key];
            }else if(key == '手机号') {
              newTraveler['mobile_no'] = traveler[key];
            }else if(key == '出生日期') {
              newTraveler['birthday'] = traveler[key];
            }else if(key == '国籍') {
              newTraveler['nationality'] = traveler[key];
            }
          }
          travelersStore.editingTraveler = newTraveler;
          travelersStore.update();
        });

        message.success('导入成功');
        importModalVisible.value = false;
      }
    }

    return {
      routeName,
      travelers,
      travelersSpinning,
      selectedTravelerIndex,
      selectedTraveler,
      editingTraveler,
      selectTraveler,
      editModalVisible,
      addTraveler,
      updateTraveler,
      deleteTraveler,
      openImportModal,
      importModalVisible,
      travelerContent,
      handleOk,
    }
  },
});
</script>

<style lang="less">
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
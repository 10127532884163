import http from "../http-common";

const base_url = 'http://localhost:8012/index.php'
// const base_url = 'http://103.133.179.163:8000'
class ApiServices {

    sendCode(data) {
        return http.post(base_url + "/login/agent/code", data)
    }

    checkToken(data) {
        return http.post(base_url + "/login/agent/token", data)
    }

    login(data) {
        return http.post(base_url + "/login/agent/check", data)
    }

    bookingsList(data) {
        return http.get('https://www.fastmock.site/mock/8a9252cffc7d9d2e8ef259a24f0a873f/bookings/list', data)
    }

    travelerList(data) {
        return http.post(base_url + '/traveler/search', data)
    }

    travelerUpdate(data) {
        return http.post(base_url + '/traveler/update', data)
    }

    productList(data) {
        return http.post(base_url + '/product/search', data)
    }

    productUpdate(data) {
        return http.post(base_url + '/product/update', data)
    }

    supplierList(data) {
        return http.post(base_url + '/supplier/search', data)
    }

    supplierUpdate(data) {
        return http.post(base_url + '/supplier/update', data)
    }

    productCategoryList(data) {
        return http.post(base_url + '/productcategory/search', data)
    }

    tenantList(data) {
        return http.post(base_url + '/agent/tenant/index', data)
    }

    tenantUpdate(data) {
        return http.post(base_url + '/agent/tenant/update', data)
    }

    contractList(data) {
        return http.post(base_url + '/agent/contract/index', data)
    }

    articleList(data) {
        return http.post(base_url + '/agent/article/index', data)
    }

    articleDetail(data) {
        return http.post(base_url + '/agent/article/read', data)
    }

    articleUpdate(data) {
        return http.post(base_url + '/agent/article/update', data)
    }

    categoryList(data) {
        return http.post(base_url + '/agent/category/index', data)
    }


    artworkList(data) {
        return http.post(base_url + '/api/artwork/list', data)
    }
    billingList(data) {
        return http.post(base_url + '/api/billing/list', data)
    }

    bidsList(data) {
        return http.post(base_url + '/api/bids/list', data)
    }
    financeList(data) {
        return http.post(base_url + '/api/finance/list', data)
    }
    financeUpdate(data) {
        return http.post(base_url + '/api/finance/update', data)
    }
    accountList(data) {
        return http.post(base_url + '/api/account/list', data)
    }
    accountUpdate(data) {
        return http.post(base_url + '/api/account/update', data)
    }
    accountDelete(id) {
        return http.post(base_url + '/api/account/delete', id)
    }
    ownerList(data) {
        return http.post(base_url + '/api/owner/list', data)
    }
    adminList(data) {
        return http.post(base_url + '/api/admin/list', data)
    }
    adminUpdate(data) {
        return http.post(base_url + '/api/admin/update', data)
    }
    adminDelete(id) {
        return http.post(base_url + '/api/admin/delete', id)
    }

    workerList(data) {
        return http.post("/api/worker/list", data)
    }

    workerSync() {
        return http.post("/api/worker/sync");
    }

    groupList(data) {
        return http.post("/api/group/list", data)
    }

    groupSync() {
        return http.post("/api/group/sync")
    }

    getProject() {
        return http.post("/api/project/detail")
    }

    attendanceList(data) {
        return http.post("/api/attendance/list", data)
    }

    planList(data) {
        return http.post("/api/plan/list", data)
    }

    planUpdate(data) {
        return http.post("/api/plan/update", data)
    }

    planItems(data) {
        return http.post("/api/plan/items", data)
    }

}
export default new ApiServices();

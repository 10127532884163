import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';
import { message } from 'ant-design-vue';

// 供应商
export const useBookingsStore = defineStore({
  id: 'bookings',
  state: () => ({
    bookings: [],
    summary: [],
    current_order_no: '',
    loading: false,
    modalVisible: false,
    modalTitle: '',
    newModalVisible: false,
    newModalTitle: '',
    syncModalVisible: false,
    memoModalVisible: false,
    emailModalVisible: false,
    relatedModalVisible: false,
    assignModalVisible: false,
    tagModalVisible: false,
    advancedModalVisible: false,
    exportModalVisible: false,
    selectedRowKeys: [],
    exportResult: [],
    searchState: {
        mode: '',
        pageNum: 1,
        pageSize: 10,
        product_code: undefined,
        product_category_id: undefined,
        supplier_id: undefined,
        source: undefined,
        ota_order_no: '',
        order_no: '',
        taobao_buyer_nick: '',
        name: '',
        phone: '',
        email: '',
        book_date: null,
        travel_date: null,
        customer_service_id: undefined,
        is_closed: "-1",
        is_canceled: "-1",
        is_abnormal: "-1",
        is_completed: "-1",
        is_after_sales: "-1",
        is_sent_email: "-1",
        is_insurance: "-1"
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    spinningState: {
      editBookingSpinning: false,
    },
    newBookingState: {
      product_id: '',
      source: '',
      ota_order_no: '',
      taobao_buyer_nick: '',
      adult_num: '',
      child_num: '',
      unit_num: '',
      actual_amount: ''
    },
    bookingDetail: {},
    batchAssignState: {
      customer_service_id: '',
    },
    batchTagState: {},
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchAll() {
      this.loading = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/booking`, this.searchState)
      .then(
        response => response
      )
      .then(data => {
        console.log('bookings data', data);
        if (data.code === 520) {
          this.bookings = data.message.data;
          this.summary = data.summary;
          this.searchState = { ...this.searchState, total: data.message.total };
          this.pagination = this.computedPagination;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },
    async update() {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/updateBooking`, this.bookingDetail)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');

          // 更新详情
          this.detail(this.bookingDetail.order_no);
          this.fetchAll();

          // this.bookings = data.list.data;
          // this.searchState = { ...this.searchState, total: data.list.total };
          // this.pagination = this.computedPagination;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },

    async add() {
      this.loading = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/addBooking`, this.newBookingState)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
          this.newModalVisible = false;
          this.loading = false;
          this.current_order_no = data.message;
          this.fetchAll();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },

    async detail(order_no) {
      this.spinningState.editBookingSpinning = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/detailBooking`, {order_no: order_no})
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.bookingDetail = data.message;
          this.current_order_no = data.message.order_no;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.spinningState.editBookingSpinning = false;
      });
    },

    async batchAssign(customer_service_id) {
      let payload = {
        order_no_arr: this.selectedRowKeys,
        customer_service_id: customer_service_id
      };
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/batchAssignBooking`, payload)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.assignModalVisible = false;
        this.fetchAll();
      });
    },

    async batchTag() {
      let payload = {
        order_no_arr: this.selectedRowKeys,
        tags: this.batchTagState
      };
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/batchTagsBooking`, payload)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.tagModalVisible = false;
        this.fetchAll();
      });
    },

    async changeTags(tag) {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/tagsBooking`, {tag: tag, order_no: this.bookingDetail.order_no})
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
          this.fetchAll();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
    },
    async exportBookings() {
      this.loading = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/exportBooking`, this.searchState)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.exportResult.push({
            key: data.message.key,
            url: data.message.url
          });
          this.exportResult.reverse();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },
    async exportTravelers() {
      this.loading = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/exportTraveler`, this.searchState)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.exportResult.push({
            key: data.message.key,
            url: data.message.url
          });
          this.exportResult.reverse();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },

    async copyBooking() {
      let payload = {order_no:this.bookingDetail.order_no};
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/copyBooking`, payload)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.detail(data.message);
          message.success('复制成功');
          this.fetchAll();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
    },

    async syncTmallOrder(values) {

      if (values.store_id == '' || values.tid == '') {
        message.error('请选择店铺并填写订单号');
        return false;
      }

      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/syncTmallOrder`, values)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
          this.syncModalVisible = false;
          this.fetchAll();

        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },

  }
});
<template>
  <a-layout id="app" >
    <a-config-provider
      :theme="theme"
    >
      <router-view />
    </a-config-provider>
  </a-layout>
</template>

<script>
import { theme } from 'ant-design-vue';
import moment from "moment";
import { defineComponent, createVNode, computed, reactive, ref, h } from 'vue';

export default defineComponent({
  setup() {

    const theme = {
      token: {
        colorPrimary: "#00b96b"
      }
    };
    return {
      theme
    }
  }
});
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>

<template>
<div class="login-div">
  <div class="login-area">
    <h1>
      <MyIcon type="icon-fenxiaoshang" /> 
      Fast Book
    </h1>
    <a-card  class="login-card">
      <h2>登录</h2>
      <a-form
        :model="formState"
        name="normal_login"
        class="login-form"

        @finish="onFinish"
        @finishFailed="onFinishFailed"
      >
        <a-form-item
          name="username"
          :rules="[{ required: true, message: '请填写账号!' }]"
        >
          <a-input class="input" placeholder="账号" v-model:value="formState.username">
          </a-input>
        </a-form-item>

        <a-form-item
          name="password"
          :rules="[{ required: true, message: '请填写密码!' }]"
        >
          <a-input class="input" type="password" placeholder="密码" v-model:value="formState.password">
          </a-input>
        </a-form-item>

        <a-form-item
          class="login-btn-item"
          style="text-align:center"
        >
          <a-button block size="large" type="primary" html-type="submit" class="login-form-button">
            确认登录
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>

</div>
</template>
<script>
import { reactive, computed, ref } from 'vue';
import router from '@/router';
import { message } from 'ant-design-vue';

import { storeToRefs } from 'pinia';
import { useLoginStore } from "@/stores";

export default({

  setup() {

    const iconLoading = ref(false);

    document.title = "Fast Book";

    const store = useLoginStore();
    const { login } = storeToRefs(store);

    const formState = reactive({
      username: '',
      password: '',
    });

    async function onFinish(values) {
      await store.login(values.username, values.password);
      router.push({path: "/orders/bookings?spm="  + Math.random()});
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const disabled = computed(() => {
      return !(formState.phone && formState.code);
    });
    return {
      iconLoading,
      formState,
      onFinish,
      onFinishFailed,
      disabled,
    };
  },

});
</script>
<style>
/* top: calc(14% + 60px); */
.login-div {
  background-image: url('@/assets/login_bg.png');
  background-size: cover;
  min-height: 680px;
  height: 100vh;
}
.login-area {
  margin-top: 11%;
}

.login-area h1 {
  text-align: center;
  color: #02257A;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 30px;
}

.login-area h2 {
  margin-bottom: 30px;
}

.login-card {
  width: 360px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}


.login-form-button {
  margin-top: 25px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.input {
  border: none;
  border-bottom: 1px solid #ddd;
}



</style>
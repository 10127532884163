import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    legacy: false,
    locale: 'zh',
    messages: {
      en: require('./lang/en.json'),
      zh: require('./lang/zh.json')
    },
    lazy: true
})

export default i18n
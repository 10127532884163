<template>
<div>

  <a-row class="page-header">
    <a-col :span="24">
      <h2>{{routeName?routeName:"Untitled"}}</h2>
    </a-col>
  </a-row>

  <a-divider />

  <a-row :gutter="24">

    <a-col :span="16">
        <a-form
          :model="formState"
          name="editForm"
          ref="formRef"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          autocomplete="off"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
        >
          <a-form-item
              name="id"
              :style="{display: 'None'}"
            >
            <a-input type="hidden" v-model:value="formState.id" />
          </a-form-item>
          <a-form-item
            label="标题"
            name="title"
            :rules="[{ required: true, message: '请填写标题' }]"
          >
            <a-input v-model:value="formState.title" />
          </a-form-item>
          <a-form-item
            label="分类"
            name="cid"
            :rules="[{ required: true, message: '请选择分类' }]"
          >
            <a-select
              placeholder="选择分类"
              style="width: 50%"
              v-model:value="formState.cid"
              allowClear
            >
              <a-select-option
                v-for="category in categories"
                :key="category.id"
              >
                {{ category.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="正文"
            name="content"
            :rules="[{ required: false, message: '' }]"
          >
            <div style="border: 1px solid #ccc">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editorRef"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden;"
              v-model="formState.content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="handleCreated"
            />
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 18 }">
            <a-button type="primary" :size="large" html-type="submit">确认提交</a-button>
          </a-form-item>
        </a-form>
    </a-col>

    <a-col :span="12" push="1">
      <ArticleForm @fatherMethod="loadData" ref="editForm" />
    </a-col>
  </a-row>

</div>
</template>

<script>

import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, reactive, ref, shallowRef, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import { message } from 'ant-design-vue';
import ApiServices from "@/services/ApiServices";

export default {
  components: { Editor, Toolbar },
  setup() {

    const route = useRoute();
    const routeName = route.name;
    const router = useRouter();

    const formState = reactive({
      id: '',
      cid: '',
      title: '',
      content: ''
    });
    const formEditStatus = ref(true);
    if (route.query.id) {
      formEditStatus.value = true;
    }

    const categories = ref([]);

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef();

    // 内容 HTML
    const valueHtml = ref('');

    // 模拟 ajax 异步获取内容
    onMounted(() => {
      console.log('query id', route.query.id);
      // setTimeout(() => {
      //     valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
      // }, 1500)
    })

    const toolbarConfig = {}
    const editorConfig = { placeholder: '请输入内容...' }

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    const readArticle = () => {
      let params = {
        id: route.query.id
      }
      ApiServices.articleDetail(params)
      .then(response => {
        let detail = response.data.data.detail;
        for(let key in formState) {
          formState[key] = detail[key];
        }
        console.log(formState);
      })
    }

    readArticle();

    const loadCategories = () => {
      const params = {
        pageNum: 1,
        pageSize: 100
      }
      ApiServices.categoryList(params)
      .then(response => {
        let result = response.data.result;
        categories.value = result.data;
      })
    }

    loadCategories();

    const onFinish = values => {
      console.log('values', values);
      ApiServices.articleUpdate(values)
        .then(response => {
          let data = response.data;
          if(data.err_code === 520) {
            message.success('更新成功');
            modalVisible.value = false;
            loadDataSource();
          }else{
            message.error(data.err_msg);
          }
        })
        .catch(e => {
          console.log(e);
        });
    };

    return {
      routeName,
      formState,
      categories,
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      onFinish
    };
  }
}
</script>
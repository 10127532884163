<template>
    <div>
        Mail detail
    </div>
</template>


<script setup>
import { ref } from 'vue'

const message = ref('Hello World!')
</script>
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';
import { message } from 'ant-design-vue';

// 供应商
export const useAuthRoleStore = defineStore({
  id: 'authRole',
  state: () => ({
    roles: [],
    loading: false,
    modalVisible: false,
    modalTitle: '',
    searchState: {
      pageNum: 1,
      pageSize: 10,
      supplier_name: '',
      email: '',
      status: 'enabled'
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    formState: {
      id: '',
      supplier_name: '',
      email: '',
      comment: ''
    },
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchAll() {
      this.loading = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/authRole`, this.searchState)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.roles = data.message.data;
          this.searchState = { ...this.searchState, total: data.message.total };
          this.pagination = this.computedPagination;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },
    async update(values) {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/updateAuthRole`, values)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
          this.modalVisible = false;
          this.fetchAll();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },
    async delete(record) {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/deleteAuthRole`, record)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
    },
  }
});
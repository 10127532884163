<template>
<div>
  <a-row class="page-header mb-3">
    <a-col :span="24">
      <h2>{{routeName ? $t(routeName) : "Untitled"}}</h2>
    </a-col>
  </a-row>

  <a-row :gutter="24">

    <a-col :span="24">

      <a-row class="page-search mb-4">
        <a-col :span="24">
          <a-form
            layout="inline"
            :model="searchState"
            @finish="search"
          >
            <a-form-item>
              <a-input v-model:value="searchState.username" placeholder="账号"></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 180px"
                placeholder="角色"
                allowClear
                v-model:value="searchState.role_id"
                :options="roles.map(item => ({label: item.name, value: item.id}))"
              >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                v-model:value="searchState.status"
                optionFilterProp="label"
                allowClear="true"
                placeholder = '状态'
                style="width:100px"
              >
                <a-select-option value="enabled" >启用</a-select-option>
                <a-select-option value="disabled" >停用</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-space>
                <a-button type="default" html-type="submit">
                  <MyIcon type="icon-search1" />
                  {{ $t('actions.search') }}
                </a-button>
                <a-button type="default" @click="()=>{this.$router.go(0);}">
                  <MyIcon type="icon-reload" />
                  {{ $t('actions.reset') }}
                </a-button>
                <a-button type="primary" @click="createRecord()" class="ml-3">
                  <MyIcon type="icon-iconfontjiahao1" />
                  {{ $t('actions.add') }}
                </a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-table
        :columns="columns"
        :data-source="accounts"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ text, record, column }">
          <template v-if="column.dataIndex == 'username'">
            <a-space>
              <a @click="editRecord(record)">{{ text }}</a>
              <a-tag v-if="record.is_main === 1">主账号</a-tag>
            </a-space>
          </template>
          <template v-if="column.dataIndex == 'status'">
            <a-switch v-if="record.is_main != 1" :checked="text == 'enabled'" @change="changeStatus(record)"/>
            <span v-else></span>
          </template>
          <template v-if="column.dataIndex == 'actions'">
            <a-space>
              <a-button @click="editRecord(record)" ><MyIcon type="icon-edit"/>{{ $t('actions.edit') }}</a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>

  <a-modal
    v-model:visible="modalVisible"
    v-model:title="modalTitle"
    :maskClosable=false
    style="width: 680px"
    :footer="null"
    distroyOnclose="true"
  >
    <a-form
      :model="formState"
      name="editForm"
      :ref="formRef"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="finishRecordForm"
    >
      <a-form-item
          name="id"
          :style="{display: 'None'}"
          v-if="formState.id"
        >
        <a-input type="hidden" v-model:value="formState.id" />
      </a-form-item>
      <a-form-item
        label="账号"
        name="username"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-input v-if="formState.id" v-model:value="formState.username" readonly>
          <template #prefix>
            {{ tenant.prefix }}_
          </template>
        </a-input>
        <a-input v-else v-model:value="formState.username">
          <template #prefix>
            {{ tenant.prefix }}_
          </template>
        </a-input>
        <div class="form-item-extra" slot="extra">设置后无法更改</div>
      </a-form-item>

      <a-form-item
        label="密码"
        name="password"
        :rules="[{ required: !formState.id, message: '必填项目' }]"
      >
        <a-input v-if="!formState.id" v-model:value="formState.password" />
        <a-input v-else v-model:value="formState.password" placeholder='不修改请留空' />
      </a-form-item>

      <a-form-item
        label="Email"
        name="email"
        :rules="[{ required: false}]"
      >
        <a-input v-model:value="formState.email" />
      </a-form-item>

      <a-form-item
        label="手机号"
        name="phone"
        :rules="[{ required: false}]"
      >
        <a-input v-model:value="formState.phone" />
      </a-form-item>

      <a-form-item
        label="备注"
        name="remark"
        :rules="[{ required: false}]"
      >
        <a-textarea v-model:value="formState.remark" />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 6, span: 16 }">
        <a-button type="primary" html-type="submit">
          {{ $t('actions.submit') }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>

</div></template>

<script>

import { defineComponent, computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";

import { storeToRefs } from 'pinia';
import { useAccountsStore } from "@/stores";
import { useTenantStore } from '@/stores';
import { useAuthRoleStore } from '@/stores';

export default defineComponent({
  components: {},
  setup() {

    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);
    const formRef = ref(null);

    const store = useAccountsStore();
    const { accounts, searchState, formState, pagination, loading, modalVisible, modalTitle } = storeToRefs(store);

    const roleStore = useAuthRoleStore();
    roleStore.searchState.pageSize = 100;
    const { roles } = storeToRefs(roleStore);

    const columns = [
      {
        title: '账号',
        dataIndex: "username",
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: '手机号',
        dataIndex: 'phone',
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
      {
        title: '启用',
        dataIndex: 'status'
      },
      {
        title: t('fields.actions'),
        dataIndex: "actions",
        width: '10%'
      }
    ];

    // 初始化
    store.fetchAll();
    roleStore.fetchAll();

    const tenantStore = useTenantStore();
    const { tenant } = storeToRefs(tenantStore);
    tenantStore.detail();

    // 搜索
    const search = () => {
      store.fetchAll();
    }

    // 表格分页
    const handleTableChange = (page, filters, sorter) => {
      store.searchState.pageNum = page.current;
      store.searchState.pageSize = page.pageSize;
      store.fetchAll();
    };

    // 创建记录表单
    const createRecord = () => {
      store.modalVisible = true;
      store.modalTitle = t('actions.add') + t('menu.accounts');
      for(let key in store.formState) {
        store.formState[key] = '';
      }
    }

    // 编辑记录表单
    const editRecord = (record) => {
      store.modalVisible = true;
      store.modalTitle = t('actions.edit') + t('menu.accounts');
      for(let key in store.formState) {
        if (key != 'password') {
          store.formState[key] = record[key];
        }
        if (key == 'password') {
          store.formState[key] = '';
        }
        if (key == 'username') {
          store.formState[key] = record[key].replace(tenantStore.tenant.prefix + '_', '');
        }
      }
    }

    // 编辑表单提交
    async function finishRecordForm(values) {
      await store.update(values);
      store.modalVisible = false;
      store.fetchAll();
    };

    async function changeStatus (record){
      const index = store.accounts.findIndex((item) => item.id === record.id);
      if (index >= 0) {
        await store.changeStatus(record);
      }
    }

    return {
      routeName,
      search,
      searchState,
      columns,
      accounts,
      roles,
      tenant,
      pagination,
      handleTableChange,
      formRef,
      loading,
      modalVisible,
      modalTitle,
      createRecord,
      editRecord,
      formState,
      finishRecordForm,
      changeStatus
    }
  }
});
</script>
<template>
    <div>
      <a-spin :spinning="spinningState.listSpinning">
        <a-table
          :dataSource="bookings"
          :columns="columns"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #bodyCell="{ text, record, column }">
            <template v-if="column.dataIndex == 'content'">
              <h3>
                <a-typography-text type="secondary">
                  {{ text }}
                </a-typography-text>
              </h3>
              <p>{{ record.create_time }}</p>
            </template>
            <template v-if="column.dataIndex == 'project'">
              <div>{{ record.project_name }}</div>
              <div>{{ record.package }}</div>
            </template>
            <template v-if="column.dataIndex == 'booker'">
              <div>淘宝昵称：{{ record.taobao_buyer_nick }}</div>
              <div>姓名：{{ record.name }}</div>
              <div>手机：{{ record.phone }}</div>
            </template>
            <template v-if="column.dataIndex == 'actions'">
              <a-space
                direction="vertical"
              >
                <a-popconfirm
                  title="确认合并选中订单的预订人和出行人信息到当前订单吗？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="syncTravelers(record)"
                  @cancel="showDeleteConfirm = false"
                >
                  <a-button >同步信息</a-button>
                </a-popconfirm>
                <!-- <a-button>合并订单</a-button> -->
              </a-space>
            </template>
          </template>
        </a-table>
      </a-spin>
    </div>
  </template>

<script>
  import { defineComponent, onMounted, ref} from 'vue';
  import { useRoute } from 'vue-router';
  import { message } from 'ant-design-vue';
  import { useI18n } from "vue-i18n";

  import { storeToRefs } from 'pinia';
  import { useRelatedbookingsStore, useBookingsStore, useTravelersStore } from '@/stores';


  export default defineComponent({
    props: ['current_order_no'],
    components: {},
    setup(props, ctx) {

      const { t } = useI18n();
      const routeName = useRoute().name;
      document.title = t(routeName);

      const store = useRelatedbookingsStore();
      const {
        bookings,
        spinningState,
        pagination,
      } = storeToRefs(store);

      store.searchState.order_no = props.current_order_no;

      const bookingsStore = useBookingsStore();
      const travelersStore = useTravelersStore();

      const columns = [
        {
          title: '预订项目',
          dataIndex: 'project',
        },
        {
          title: '出行日期',
          dataIndex: 'travel_date',
        },
        {
          title: '预订人',
          dataIndex: 'booker'
        },
        {
          title: '出行人',
          dataIndex: 'travelers_count'
        },
        {
          title: "操作",
          dataIndex: 'actions',
        }
      ];

      // 初始化
      onMounted(() => {
        store.fetchAll();
        store.editingMemo = {};
      });

      const addMemo = () => {
        store.editModalVisible = true;
        store.editingMemo = {};
      }

      const updateMemo = () => {
        store.update();
        message.success('更新成功');
      }

      async function syncTravelers (record) {
        let params = {
          current_order_no: props.current_order_no,
          sync_order_no: record.order_no
        }
        await store.syncTravelers(params);
        bookingsStore.detail(props.current_order_no);
        travelersStore.searchState.order_no = props.current_order_no;
        travelersStore.fetchAll();
        bookingsStore.relatedModalVisible = false;
      }

      const handleTableChange = (page, filters, sorter) => {
        store.searchState.pageNum = page.current;
        store.searchState.pageSize = page.pageSize;
        store.fetchAll();
      };

      return {
        routeName,
        bookings,
        columns,
        spinningState,
        addMemo,
        updateMemo,
        syncTravelers,
        pagination,
        handleTableChange
      }
    },
  });
  </script>

  <style lang="less">
  .editable-cell {
    position: relative;
    .editable-cell-input-wrapper,
    .editable-cell-text-wrapper {
      padding-right: 24px;
    }

    .editable-cell-text-wrapper {
      padding: 5px 24px 5px 5px;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
      position: absolute;
      right: 0;
      width: 20px;
      cursor: pointer;
    }

    .editable-cell-icon {
      margin-top: 4px;
      display: none;
    }

    .editable-cell-icon-check {
      line-height: 28px;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
      color: #108ee9;
    }

    .editable-add-btn {
      margin-bottom: 8px;
    }
  }
  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }
  </style>
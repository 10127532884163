<template>
<div>
  <a-row class="page-header mb-3">
    <a-col :span="24">
      <h2>{{routeName ? $t(routeName) : "Untitled"}}</h2>
    </a-col>
  </a-row>

  <a-row :gutter="24">
    <a-col :span="24">
      <a-row class="page-search mb-4">
        <a-col :span="24">
          <a-form
            layout="inline"
            :model="searchState"
            @finish="search"
          >
            <a-form-item>
              <a-input
                v-model:value="searchState.name"
                :placeholder="$t('fields.title')"
                allowClear="true"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                v-model:value="searchState.code"
                placeholder="编码"
                allowClear="true"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 180px"
                placeholder="供应商"
                allowClear="true"
                v-model:value="searchState.supplier_id"
                :options="suppliers.map(item => ({label: item.supplier_name, value: item.id}))"
              >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                show-search
                optionFilterProp="label"
                style="width: 120px"
                placeholder="产品分类"
                allowClear="true"
                v-model:value="searchState.category_id"
                :options="productCategories.map(item => ({label: item.name, value: item.id}))"
              >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                v-model:value="searchState.status"
                optionFilterProp="label"
                allowClear="true"
                placeholder = '状态'
                style="width:100px"
              >
                <a-select-option value="enabled" >启用</a-select-option>
                <a-select-option value="disabled" >停用</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-space>
                <a-button type="default" html-type="submit">
                  <MyIcon type="icon-search1" />
                  {{ $t('actions.search') }}
                </a-button>
                <a-button type="default" @click="()=>{this.$router.go(0);}">
                  <MyIcon type="icon-reload" />
                  {{ $t('actions.reset') }}
                </a-button>
                <a-button type="primary" @click="createRecord()" class="ml-3">
                  <MyIcon type="icon-iconfontjiahao1" />
                  {{ $t('actions.add') }}
                </a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-table
        size="middle"
        :columns="columns"
        :data-source="products"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ text, record, column }">
          <template v-if="column.dataIndex == 'name'">
            <a @click="editRecord(record)">{{ text }}</a>
          </template>
          <template v-if="column.dataIndex == 'status'">
            <a-switch :checked="text == 'enabled'" @change="changeStatus(record)"/>
          </template>
          <template v-if="column.dataIndex == 'actions'">
            <a-space>
              <a-button @click="editRecord(record)" ><MyIcon type="icon-edit"/>{{ $t('actions.edit') }}</a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>

  <a-modal
    v-model:visible="modalVisible"
    v-model:title="modalTitle"
    :maskClosable=false
    style="width: 680px"
    :footer="null"
  >
    <a-form
      :model="formState"
      name="editForm"
      ref="formRef"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="finishRecordForm"
    >
      <a-form-item
          name="id"
          :style="{display: 'None'}"
          v-if="'id' in formState"
        >
        <a-input type="hidden" v-model:value="formState.id" />
      </a-form-item>
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-input v-model:value="formState.name" />
      </a-form-item>
      <a-form-item
        label="分类"
        name="category_id"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-select
          show-search
          optionFilterProp="label"
          v-model:value="formState.category_id"
          :options="productCategories.map(item => ({label: item.name, value: item.id}))"
        >
        </a-select>
      </a-form-item>

      <a-form-item
        label="供应商"
        name="supplier_id"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-select
          show-search
          optionFilterProp="label"
          v-model:value="formState.supplier_id"
          :options="suppliers.map(item => ({label: item.supplier_name, value: item.id}))"
        >
        </a-select>
      </a-form-item>

      <a-form-item
        label="计费方案"
        name="billing_method"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-radio-group v-model:value="formState.billing_method">
          <a-radio value="by_crowd">按人群（成人/儿童）</a-radio>
          <a-radio value="by_unit">数量（车辆/件数）</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        label="成本价"
        style="padding: 0; margin: 0;"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-row :gutter="12">
          <a-col :span="8">
            <a-form-item
              name="adult_cost"
              :rules="[{ required: formState.billing_method == 'by_crowd', message: '必填项目' }]"
            >
              <a-input v-model:value="formState.adult_cost" v-if="formState.billing_method == 'by_crowd'" placeholder="成人价" />
              <a-input v-else readonly placeholder='成人价' />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              name="child_cost"
              :rules="[{ required: formState.billing_method == 'by_crowd', message: '必填项目' }]"
            >
              <a-input name="child_cost" v-model:value="formState.child_cost" v-if="formState.billing_method == 'by_crowd'" placeholder="儿童价" />
              <a-input v-else readonly placeholder='儿童价' />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              name="unit_cost"
              :rules="[{ required: formState.billing_method == 'by_unit', message: '必填项目' }]"
            >
              <a-input v-model:value="formState.unit_cost" v-if="formState.billing_method == 'by_unit'" placeholder="单位价" />
              <a-input v-else readonly placeholder='单位价' />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item
        label="库存类型"
        name="stock_type"
        :rules="[{ required: true, message: '必填项目' }]"
      >
        <a-radio-group v-model:value="formState.stock_type">
          <a-radio value="calendar">日历</a-radio>
          <a-radio value="entity">实物</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        label="备注"
        name="comment"
        :rules="[{ required: false, message: '' }]"
      >
        <a-textarea v-model:value="formState.comment" />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 6, span: 16 }">
        <a-button type="primary" html-type="submit">
          {{ $t('actions.submit') }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>

</div>
</template>

<script>
import { defineComponent, computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";
import ApiServices from "@/services/ApiServices";

import { storeToRefs } from 'pinia';
import { useProductsStore, useSuppliersStore, useProductCategoriesStore } from "@/stores";

export default defineComponent({
  props: {
    msg: String,
  },
  components: {},
  setup() {
    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);

    const productsStore = useProductsStore();
    const { products, searchState, formState, pagination, loading, modalVisible, modalTitle } = storeToRefs(productsStore);

    const suppliersStore = useSuppliersStore();
    const { suppliers } = storeToRefs(suppliersStore);
    suppliersStore.searchState.pageSize = 100;
    suppliersStore.fetchAll();

    const productCategoriesStore = useProductCategoriesStore();
    const { productCategories } = storeToRefs(productCategoriesStore);
    productCategoriesStore.fetchAll();

    const columns = [
      {
        title: t('fields.id'),
        dataIndex: "id",
        width: '5%'
      },
      {
        title: t('fields.productCode'),
        dataIndex: "code",
        width: '10%'
      },
      {
        title: t('fields.title'),
        dataIndex: "name",
        width: '30%'
      },
      {
        title: t('fields.category'),
        dataIndex: ['category', 'name'],
        width: '10%'
      },
      {
        title: t('fields.supplier'),
        dataIndex: ['supplier', 'supplier_name']
      },
      {
        title: '启用',
        dataIndex: 'status'
      },
      {
        title: t('fields.actions'),
        dataIndex: "actions",
        width: '10%'
      }
    ];

    // 初始化
    productsStore.searchState.pageSize = 10;
    productsStore.searchState.pageNum = 1;
    productsStore.fetchAll();

    // 搜索
    const search = () => {
      productsStore.fetchAll();
    }

    const formRef = ref();

    const handleTableChange = (page, filters, sorter) => {
      productsStore.searchState.pageNum = page.current;
      productsStore.searchState.pageSize = page.pageSize;
      productsStore.fetchAll();
    };

    // 创建记录表单
    const createRecord = () => {
      productsStore.modalVisible = true;
      productsStore.modalTitle = t('actions.add') + t('menu.products');
      for (let key in productsStore.formState) {
        productsStore.formState[key] = '';
      }
    }

    // 编辑记录表单
    const editRecord = (record) => {
      productsStore.modalVisible = true;
      productsStore.modalTitle = t('actions.edit') + t('menu.products');
      for(let key in productsStore.formState) {
        productsStore.formState[key] = record[key];
      }
    }

    // 编辑表单提交
    async function finishRecordForm(values) {
      await productsStore.update(values);
      productsStore.modalVisible = false;
      productsStore.fetchAll();
    };

    async function changeStatus (record){
      const index = productsStore.products.findIndex((item) => item.id === record.id);
      if (index >= 0) {
        await productsStore.changeStatus(record);
      }
    }


    return {
      routeName,
      products,
      productCategories,
      suppliers,
      search,
      searchState,
      columns,
      pagination,
      handleTableChange,
      loading,
      modalVisible,
      modalTitle,
      createRecord,
      editRecord,
      formState,
      finishRecordForm,
      formRef,
      changeStatus
    }
  },
});
</script>
<template>
<div>
  <a-row class="page-header">
    <a-col :span="24">
      <h2>{{routeName ? $t(routeName) : "Untitled"}}</h2>
    </a-col>
  </a-row>

  <a-divider />

  <a-row :gutter="24">

    <a-col :span="24">

      <a-row class="page-search mb-3">
        <a-col :span="18">
          <a-form
            layout="inline"
            :model="searchState"
            @finish="search"
          >
            <a-form-item>
              <a-input v-model:value="searchState.phone" placeholder="Tour"></a-input>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="searchState.phone" placeholder="Date"></a-input>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="searchState.nickname" placeholder="Name"></a-input>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit">
                <MyIcon type="icon-search1" />
                Search
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="default" @click="()=>{this.$router.go(0);}">
                <MyIcon type="icon-reload" />
                Reset
              </a-button>
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :span="6" class="text-right">
          <a-button type="primary" @click="createRecord()">
            <MyIcon type="icon-iconfontjiahao1" />
            New
          </a-button>
        </a-col>
      </a-row>

      <a-table
        size="middle"
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ text, record, column }">
          <template v-if="column.dataIndex == 'avatar'">
            <MyIcon type="icon-avatar" :style="{fontSize: '32px'}"/>
          </template>
          <template v-if="column.dataIndex == 'owner'">
            {{ record.owner.name }}
            <br/>
            {{ record.owner.mobile }}
          </template>
          <template v-if="column.dataIndex == 'operation'">
             <a-space>
                <!-- <a-button @click="detail(record)">
                
                </a-button> -->
              <a-button @click="editRecord(record)">
                 <MyIcon type="icon-edit" />
                 Detail
              </a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>

  <a-modal
    v-model:visible="modalVisible" 
    v-model:title="modalTitle"
    :maskClosable=false
    :footer="null"
    @ok="handleOk"
  >
    <a-form
      :model="formState"
      name="editForm"
      ref="formRef"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <a-form-item
          name="id"
          :style="{display: 'None'}"
          v-if="formEditStatus == true"
        >
        <a-input type="hidden" v-model:value="formState.id" />
      </a-form-item>
      <a-form-item
        label="手机号"
        name="phone"
        :rules="[{ required: true, message: '请填写手机号' }]"
      >
        <a-input v-if="formEditStatus == false" v-model:value="formState.phone" />
        <span v-else>{{ formState.phone }}</span>
      </a-form-item>
      <a-form-item
        label="别称"
        name="nickname"
        :rules="[{ required: false, message: '' }]"
      >
        <a-input v-model:value="formState.nickname" />
      </a-form-item>

      <a-form-item
        label="备注"
        name="remark"
        :rules="[{ required: false, message: '' }]"
      >
        <a-textarea v-model:value="formState.remark" />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
        <a-button type="primary" html-type="submit">确认提交</a-button>
      </a-form-item>
    </a-form>
  </a-modal>

</div>
</template>

<script>

import { defineComponent, computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import ApiServices from "@/services/ApiServices";

const columns = [
  {
    title: "Id",
    dataIndex: "avatar"
  },
  {
    title: "Tour",
    dataIndex: "phone"
  },
  {
    title: "Date",
    dataIndex: "nickname"
  },
  {
    title: "Name",
    dataIndex: "remark"
  },
  {
    title: "Hotel",
    dataIndex: "create_time_format"
  },
  {
    title: "Pax",
    dataIndex: "operation"
  },
  {
    title: "charged",
    dataIndex: "charged"
  },
  {
    title: "PickUpTime",
    dataIndex: "PickUpTime"
  },
  {
    title: "Car Number",
    dataIndex: "carnumber"
  }
];


export default defineComponent({
  props: {
    msg: String,
  },
  components: {},
  setup() {

    const searchState = reactive({
      phone: '',
      nickname: '',
      total: 0,
      pageNum: 1,
      pageSize: 10
    })

    const dataSource = ref([]);
    const route = useRoute();
    const routeName = route.name;
    const router = useRouter();
    const loading = ref(false);
    const syncLoading = ref(false);
    const modalVisible = ref(false);
    const modalTitle = ref('新增客户');
    const formEditStatus = ref(false);

    const search = () => {
      searchState.pageNum = 1;
      loadDataSource();
    }

    const formRef = ref();
    const formState = reactive({
      id: '',
      phone: '',
      nickname: '',
      remark: ''
    });

    const ownerList = ref([]);

    const loadOwnerList = () => {
      // const params = {
      //   pageNum: 1,
      //   pageSize: 100
      // }
      // ApiServices.tenantList(params)
      // .then(response => {
      //   let result = response.data.result;
      //   ownerList.value = result.data;
      // })
    }

    loadOwnerList();
    const onFinish = values => {
      console.log('Success:', values);
      ApiServices.tenantUpdate(values)
        .then(response => {
          let data = response.data; 
          if(data.err_code === 520) {
            message.success('更新成功');
            modalVisible.value = false;
            loadDataSource();
          }else{
            message.error(data.err_msg);
          }
        })
        .catch(e => {
          console.log(e);
        });
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const confirmDelete = (record_id) => {
      let post_data = {
        record_id: record_id
      }
      ApiServices.accountDelete(post_data)
        .then(response => {
          let data = response.data;
          if(data.code === 520) {
            message.success('更新成功')
            loadDataSource()
          }else{
            message.error('更新失败')
          }
        })
        .catch(e => {
          console.log(e);
        });
    }

    const loadDataSource = () => {
      loading.value = true;
      ApiServices.tenantList(searchState)
        .then(response => {
          let result = response.data.result;
          if(response.data.err_code === 520) {
            dataSource.value = result.data;
            searchState.total = result.total;
          }else{
            console.log('error')
          }
          loading.value = false;
        })
        .catch(e => {
          console.log(e);
        });
    }

    const pagination = computed(() => ({
      total: searchState.total,
      current: searchState.pageNum,
      pageSize: searchState.pageSize,
      defaultPageSize: 10,
      showTotal:  total =>`总共 ${total} 项`,
      showSizeChanger: true,
      showQuickJumper: true
    }))

    const handleTableChange = (page, filters, sorter) => {
      searchState.pageNum = page.current;
      searchState.pageSize = page.pageSize;
      loadDataSource();
    };

    loadDataSource();

    const createRecord = () => {
      modalVisible.value = true;
      modalTitle.value = '新增客户';
      formEditStatus.value = false;
      formRef.value.resetFields();
      for(let key in formState) {
        formState[key] = '';
      }
    }

    const editRecord = (record) => {
      modalVisible.value = true;
      modalTitle.value = '编辑客户';
      formEditStatus.value = true;
      for(let key in formState) {
        formState[key] = record[key];
      }
    }

    const detail = (record) => {
      router.push({
        path: 'tenantDetail',
        query: {
          id: record.id
        }
      })
    }

    const syncGroup = () => {
      syncLoading.value = true;
      // ApiServices.groupSync()
      // .then(response => {
      //   let data = response.data;
      //   if(data.err_code == 520) {
      //     message.success('更新成功', ()=>{
      //       searchState.pageNum = 1;
      //       loadDataSource();
      //     });
      //   }else{
      //     message.error('更新失败');
      //   }
      //   syncLoading.value = false;
      // })
    }

    return {
      routeName,
      searchState,
      search,
      handleTableChange,
      loadDataSource,
      columns,
      dataSource,
      pagination,
      loading,
      syncLoading,
      modalVisible,
      modalTitle,
      createRecord,
      editRecord,
      detail,
      syncGroup,
      formState,
      onFinish,
      onFinishFailed,
      formEditStatus,
      ownerList,
      formRef,
      confirmDelete
    }
  },
});
</script>
<template>
  <a-layout-sider width="220" style="background: #fff; min-height: 1200px">
    <a-menu
      mode="inline"
      v-model:selectedKeys="selectedKeys"
      v-model:openKeys="openKeys"
      :style="{ height: '100%', borderRight: 0 }"
    >
      <a-sub-menu v-for="(item) in menu_config" :key="item.path">
        <template #title>
          <span><MyIcon :type="item.icon" />
            <span style="margin-left: 10px; font-size: 15px;">{{ $t(item.name) }}</span>
          </span>
        </template>
        <a-menu-item v-for="(child) in item.children" :key="child.path">
          <router-link :to="child.path">{{ $t(child.name) }}</router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </a-layout-sider>
</template>
<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router'
import menu_data from '../menu.json';
export default defineComponent({
  components: {

  },

  setup() {
    const route = useRoute();
    let paths = route.path.split('/');
    let openkeys = ['/' + paths[1]];
    const state = reactive({
      openKeys: openkeys,
      selectedKeys: [route.path],
      menu_config: []
    });

    console.log('state', state);

    let menu_config = [];

    for(var i in menu_data) {
      let sub = {};
      sub.name = menu_data[i].name;
      sub.path = '/' + menu_data[i].route;
      sub.icon = menu_data[i].icon;
      if(menu_data[i].children) {
        let menu_children = menu_data[i].children;
        let children = [];
        for(var j in menu_children) {
          let child = {};
          child.name = menu_children[j].name;
          child.path = sub.path + "/" + menu_children[j].route;
          child.icon = menu_children[j].icon;
          children.push(child);
        }
        sub.children = children;
      }
      menu_config.push(sub);
    }

    const onOpenChange = openKeys => {
      const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);

      if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys;
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    };

    return { ...toRefs(state),
      onOpenChange,
      menu_config
    };
  },

});
</script>
<template>
<a-layout class="public-form">
    <a-row>
        <a-col>
            12323
        </a-col>
    </a-row>
</a-layout>
</template>

<script>
export default {
  data(){
    return {
      spinning: false,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);

          let that = this;
          this.spinning = true;

          this.$http.post('login/verify', values).then(response=>{
            let data = response.data;
            if(data.err_code === 1){
              // 缓存token
              sessionStorage.setItem("token", data.token);
              sessionStorage.setItem("project", data.project);
              that.$message.success('登录成功，token' + data.token, (()=>{
                that.$router.push('/content/worker');
              }));
            }else{
              that.$message.error(data.err_msg);
            }
            this.spinning = false;
          }, error=>{
            console.log(error);
            this.spinning = false;
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.public-form {
  background: #fff;
}
</style>

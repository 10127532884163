import { defineStore } from 'pinia';
import { computed } from 'vue';
import { fetchWrapper } from '@/helpers';
import { message } from 'ant-design-vue';

// 供应商
export const useTenantStore = defineStore({
  id: 'tenant',
  state: () => ({
    tenantStores: [],
    loading: false,
    modalVisible: false,
    modalTitle: '',
    formData: {
      store_id: null,
      tid: ''
    },
    tenant: {
      id: '',
      name: '',
      short_name: '',
      prefix: ''
    },
    searchState: {
      total: 100
    },
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchStores() {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/tenantStore`)
        .then(response => response)
        .then(data => {
          if (data.code === 520) {
            this.tenantStores = data.message.data || [];
            this.searchState.total = data.message.total || 0;
            if (data.message.total > 0) {
              let defaultStore = data.message.data[0];
              this.formData.store_id = defaultStore.id;
            }
            this.pagination = this.computedPagination;
          } else {
            message.error(data.message);
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
        });
    },
    async detail() {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/detailTenant`, {})
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.tenant = data.message;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },
  }
});
// import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
// import Antd from 'ant-design-vue/lib';
// import 'ant-design-vue/dist/antd.less';
import ApiServices from "@/services/ApiServices";
import { fetchWrapper } from '@/helpers';

import ContentIndex from '@/components/Content/Index';

import Dashboard from '@/components/Content/Dashboard';
import Reports from '@/components/Content/Reports';
import Bookings from '@/components/Content/Bookings';

import MailDetail from '@/components/Content/MailDetail';

import Invoices from '@/components/Content/Invoices';

import ProductListing from '@/components/Content/ProductListing';;
import Suppliers from '@/components/Content/Suppliers';

import Insurances from '@/components/Content/Insurances';

import Accounts from '@/components/Content/Accounts';

import ArticleEdit from '@/components/Content/ArticleEdit';

import Login from '@/components/Login/Index';
import PublicForm from '@/components/Public/Form';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Login,
      meta: {
        requiresAuth: false,
        title: 'Fast Book'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false,
        title: 'Fast Book'
      }
    },
    {
      path: '/public/form',
      name: 'PublicForm',
      component: PublicForm,
      meta: {
        requiresAuth: false,
        title: ''
      }
    },
    {
      path: '/orders',
      name: 'orders',
      component: ContentIndex,
      children: [
        {
          path: 'bookings',
          name: 'menu.bookings',
          component: Bookings,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'insurances',
          name: 'menu.insurances',
          component: Insurances,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'maildetail',
          name: '邮件详情',
          component: MailDetail,
          meta: {
            requiresAuth: true
          }
        },
      ]
    },
    {
      path: '/products',
      name: 'products',
      component: ContentIndex,
      children: [
        {
          path: 'productListing',
          name: 'menu.productListing',
          component: ProductListing,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'suppliers',
          name: 'menu.suppliers',
          component: Suppliers,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'invoices',
          name: 'menu.invoices',
          component: Invoices,
          meta: {
            requiresAuth: true,
            title: 'Invoices'
          }
        },
      ]
    },
    {
      path: '/overview',
      name: 'overview',
      component: ContentIndex,
      children: [
        {
          path: 'dashboard',
          name: 'menu.dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
            title: 'menu.dashboard'
          }
        },
        {
          path: 'reports',
          name: 'menu.reports',
          component: Reports,
          meta: {
            requiresAuth: true,
            title: 'Reports'
          }
        }
      ]
    },
    {
      path: '/settings',
      name: 'settings',
      component: ContentIndex,
      children: [
        {
          path: '/settings/accounts',
          name: 'menu.accounts',
          component: Accounts
        }
      ]
    },

  ]
})


router.beforeEach((to, from, next) => {

  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
    // const pageTitle = i18n.$t(to.meta.title);
    // document.title = to.path;
  // }


  if (to.name !== "login") {
    let token = localStorage.getItem("token");

    if (!token) {
      router.push('/login');
    }

    fetchWrapper.post(process.env.VUE_APP_API_URL + `/login/verify`, { token })
      .then(response => response)
      .then(data => {
        if (data.code != 520) {
          router.push("/login");
        }
      })
      .catch(error => console.log(error))
      .finally(() => {});

      // ApiServices.checkToken({token: token})
      // .then(response => {
      //   let data = response.data;
      //   if(data.err_code !== 520) {
      //     router.push("/login");
      //   };
      // })
      // .catch(e => {
      //   console.log(e);
      // });
  }

  next();

})

export default router;
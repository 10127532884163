import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';


// 供应商
export const useProductCategoriesStore = defineStore({
  id: 'productCategories',
  state: () => ({
    productCategories: [],
    loading: false,
    modalVisible: false,
    modalTitle: '',
    searchState: {
        pageNum: 1,
        pageSize: 10,
        code: '',
        name: '',
        category_id: undefined,
        supplier_id: undefined
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    formState: {
        id: '',
        code: '',
        name: '',
        category_id: '',
        supplier_id: '',
        billing_method: 'by_crowd',
        stock_type: 'calendar',
        adult_cost: '',
        child_cost: '',
        unit_cost: '',
        comment: '',
    },
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchAll() {
      this.loading = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/productCategory`, this.searchState)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.productCategories = data.list.data;
          this.searchState = { ...this.searchState, total: data.list.total };
          this.pagination = this.computedPagination;
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },

  }
});
<template>
  <div>
    <div class="mb-2" style="overflow:hidden;">

        <!-- <a-space>
          <a-button @click="addRecord">{{ $t('actions.add') }}</a-button>
        </a-space> -->
        	<a-form
            :model="editingData"
            name="editForm"
            ref="formRef"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }"
            autocomplete="off"
            @finish="updateRecord"
          >
            <a-form-item
              label="供应商"
            >
              {{ bookingDetail.supplier.supplier_name }}
            </a-form-item>
            <a-form-item
              label="供应商邮箱"
            >
              <a-typography-text keyboard>
                {{ bookingDetail.supplier.email }}
              </a-typography-text>
            </a-form-item>
            <a-form-item
              label="类别"
              name="type"
              :rules="[{ required: true , message: '必填项目' }]"
            >
              <a-select
                style="width: 100%"
                v-model:value="editingData.type"
              >
                <a-select-option value="0">预订</a-select-option>
                <a-select-option value="1">修改</a-select-option>
                <a-select-option value="2">退订</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 6, span: 14 }">
              <a-button type="primary" html-type="submit">
                发送
              </a-button>
            </a-form-item>
          </a-form>
    </div>

    <a-spin :spinning="spinningState.listSpinning">
      <a-table
        :dataSource="mails"
        :columns="columns"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ text, record, column }">
          <template v-if="column.dataIndex == 'subject'">
            <h3>
              <a-typography-text type="secondary">
                <a @click="openDetailModal(record)">{{ text }}</a>
              </a-typography-text>
            </h3>
            <p>{{ record.create_time }}</p>
          </template>
          <template v-if="column.dataIndex == 'type'">
            <a-tag color="success" v-if="text === 0">
              预订
            </a-tag>
            <a-tag color="warning" v-if="text === 1">
              修改
            </a-tag>
            <a-tag color="default" v-if="text === 2">
              退订
            </a-tag>
          </template>
          <template v-if="column.dataIndex == 'actions'">
            <a-space>
              <a-popconfirm
                title="确认删除跟记录吗？"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteRecord(record)"
                @cancel="showDeleteConfirm = false"
              >
                <a><MyIcon type="icon-delete" /></a>
              </a-popconfirm>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-spin>

    <a-modal
      v-model:visible="editModalVisible"
      footer=""
    >
      <template #title>
        邮件发单
      </template>
      <a-form
        :model="editingData"
        name="editForm"
        ref="formRef"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        autocomplete="off"
        @finish="updateRecord"
      >
        <a-form-item
          label="供应商"
        >
          {{ bookingDetail.supplier.supplier_name }}
        </a-form-item>
        <a-form-item
          label="供应商邮箱"
        >
          <a-typography-text keyboard>
            {{ bookingDetail.supplier.email }}
          </a-typography-text>
        </a-form-item>
        <a-form-item
          label="类别"
          name="type"
          :rules="[{ required: true , message: '必填项目' }]"
        >
          <a-select
            style="width: 100%"
            v-model:value="editingData.type"
          >
            <a-select-option value="0">预订</a-select-option>
            <a-select-option value="1">修改</a-select-option>
            <a-select-option value="2">退订</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 6, span: 14 }">
          <a-button type="primary" html-type="submit">
            {{ $t('actions.submit') }}
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      title="邮件详情"
      style="width:1280px;top:0;height:100%;"
      v-model:visible="detailModalVisible"
    >
      <template #footer>
        <a-button key="close" @click="()=>{detailModalVisible=false}">关闭</a-button>
      </template>
      <a-form
        >

        <!-- <a-form-item
          label='标题'
          class="form-item"
        >
          {{ detail.subject }}
        </a-form-item>
        <a-form-item
          label="发件人"
          class="form-item"
        >
          {{ detail.sender }}
        </a-form-item>
        <a-form-item
          label="收件人"
          class="form-item"
        >
          {{ detail.receiver }}
        </a-form-item>
        <a-form-item
          label="发送时间"
          class="form-item"
        >
          {{ detail.create_time }}
        </a-form-item> -->
        <a-form-item
          label=""
          class="form-item"
        >
          <div v-html="detail.body" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

</template>


<script>
import { defineComponent, onMounted, ref} from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";

import { storeToRefs } from 'pinia';
import { useMailsStore } from '@/stores';

export default defineComponent({
  props: ['current_order_no', 'current_booking_detail'],
  components: {},
  setup(props, ctx) {

    const { t } = useI18n();
    const routeName = useRoute().name;
    document.title = t(routeName);
    const formRef = ref(null);

    const store = useMailsStore();

    const {
      mails,
      detail,
      spinningState,
      editingData,
      searchState,
      pagination,
      modalVisible,
      modalTitle,
      editModalVisible,
      detailModalVisible
    } = storeToRefs(store);

    store.searchState.order_no = props.current_order_no;

    const bookingDetail = ref({});
    bookingDetail.value = props.current_booking_detail;

    const columns = [
      {
        title: '类型',
        dataIndex: 'type',
        width: '10%'
      },
      {
        title: '标题',
        dataIndex: 'subject',
      },
      {
        title: '接收人',
        dataIndex: 'receiver',
        width: '12%'
      },
      {
        title: "操作",
        dataIndex: 'actions',
        width: '10%'
      }
    ];

    // 初始化
    onMounted(() => {
      store.fetchAll();
    });

    const addRecord = () => {
      store.editModalVisible = true;
      store.editingData = {};
      store.editingData.type = '预订';
    }

    async function updateRecord (values) {
      await store.update();
    }

    const deleteRecord = (item) => {
      store.delete(item);
    }

    const handleTableChange = (page, filters, sorter) => {
      store.searchState.pageNum = page.current;
      store.searchState.pageSize = page.pageSize;
      store.fetchAll();
    };

    const openDetailModal = (item) => {
      store.detailModalVisible = true;
      store.detail = item;
    }

    return {
      routeName,
      formRef,
      mails,
      detail,
      bookingDetail,
      columns,
      spinningState,
      editingData,
      editModalVisible,
      detailModalVisible,
      addRecord,
      updateRecord,
      deleteRecord,
      pagination,
      handleTableChange,
      openDetailModal
    }
  },
});
</script>

<style lang="less">
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.form-item {
  margin: 8px;
}

</style>
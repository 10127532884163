import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';
import { message } from 'ant-design-vue';

// 供应商
export const useProductsStore = defineStore({
  id: 'products',
  state: () => ({
    products: [],
    product: {},
    loading: false,
    modalVisible: false,
    modalTitle: '',
    searchState: {
        pageNum: 1,
        pageSize: 10,
        code: '',
        name: '',
        category_id: undefined,
        supplier_id: undefined,
        status: 'enabled'
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    formState: {
        id: '',
        code: '',
        name: '',
        category_id: '',
        supplier_id: '',
        billing_method: 'by_crowd',
        stock_type: 'calendar',
        adult_cost: '',
        child_cost: '',
        unit_cost: '',
        comment: '',
    },
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchAll() {
      this.loading = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/product`, this.searchState)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.products = data.list.data;
          this.searchState = { ...this.searchState, total: data.list.total };
          this.pagination = this.computedPagination;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },
    async fetchOne(id) {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/detailProduct`, {id:id})
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          this.product = data.detail;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
    },
    async update(values) {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/updateProduct`, values)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
          this.products = data.list.data;
          this.searchState = { ...this.searchState, total: data.list.total };
          this.pagination = this.computedPagination;
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
      this.loading = false;
    },
    async changeStatus(record) {
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/changeStatusProduct`, record)
      .then(
        response => response
      )
      .then(data => {
        if (data.code === 520) {
          message.success('更新成功');
          record.status = record.status == 'enabled' ? 'disabled' : 'enabled';
          this.suppliers.splice(index, 1, record);
        } else {
          message.error(data.message);
        }
      })
      .catch(error => console.log(error));
    },
  }
});
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { computed } from 'vue';
import { message } from 'ant-design-vue';

// 出行人员
export const useRelatedbookingsStore = defineStore({
  id: 'relatedbookings',
  state: () => ({
    bookings: [],
    loading: false,
    formState: {
      id: '',
      name: '',
      id_card: '',
      phone: '',
      email: '',
      passport: '',
      gender: '',
      birthday: '',
      address: '',
    },
    searchState: {
      pageNum: 1,
      pageSize: 5,
      order_no: '',
    },
    modalState: {
      visible: false,
      title: 'Title'
    },
    spinningState: {
      listSpinning: false,
      editSpinning: false,
      addSpinning: false,
      deleteSpinning: false,
    },
    pagination: {}
  }),

  getters: {
    computedPagination() {
      return computed(() => ({
        total: this.searchState.total,
        current: this.searchState.pageNum,
        pageSize: this.searchState.pageSize,
        defaultPageSize: 10,
        showTotal: total => `总共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true
      })).value;
    }
  },

  actions: {
    async fetchAll() {
      this.spinningState.listSpinning = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/relatedBooking`, this.searchState)
        .then(response => response)
        .then(data => {
          if (data.code === 520) {
            this.bookings = data.message.data || [];
            this.searchState.total = data.message.total || 0;
            this.pagination = this.computedPagination;
          } else {
            message.error(data.message);
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.spinningState.listSpinning = false;
        });
    },

    async update() {
      // this.spinningState.editSpinning = true;

      this.editingMemo.order_no = this.searchState.order_no;
      // this.editingMemo.order_no = '111111';

      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/updateMemo`, this.editingMemo)
        .then(response => response.data)
        .then(data => {
          if (data.code === 520) {

          } else {
            message.error(data.message);
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.fetchAll();
          this.editingMemo = {};
          // this.editModalVisible = false;
          this.spinningState.editSpinning = false;
        });
    },

    async syncTravelers(params) {
      this.spinningState.deleteSpinning = true;
      await fetchWrapper.post(process.env.VUE_APP_API_URL + `/api/syncTraveler`, params)
        .then(response => response.data)
        .then(data => {
          if (data.code === 520) {
          } else {
            message.error(data.message);
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.fetchAll();
          this.spinningState.deleteSpinning = false;
        });
    },

  }
});
